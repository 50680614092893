import React, {Component} from "react";
import {BaseCard, TextSize, DeleteIcon, Paragraph, StarIcon} from "medissima-ui-kit";
import {profileService} from "../akita/profile.service";

type Data = {
    id?: string|number,
    name?: string,
    email?: string,
    onDelete?: (id?: string|number) => void,
    isAdmin?: boolean
}

class User extends Component<Data> {

    adminIcon(){
        if(this.props.isAdmin){
            return <div className={"admin-status"}>
                <StarIcon/>
                <p  style={{
                    color: "#40D094",
                    fontWeight:"bold",
                    alignItems:"center",
                    display:"flex",
                    cursor: "pointer"
                }}
                >
                    Администратор</p>
            </div>
        }
    }

    render(){
        return <BaseCard className={`client-users--item ${this.props.isAdmin ? "admin" : null}`}>
            <div className={"client-users--data"}>
                <Paragraph color={"#303030"}  className={"client-users--name"} text={this.props.name} size={TextSize.P_18} />
                <Paragraph color={"#6C8CC8"} size={TextSize.P_18} className={"client-users--email"} text={this.props.email} />
            </div>
            <div className={"client-users--action"}>
                {this.adminIcon()}
                <DeleteIcon className={"delete-icon"} onClick={() => this.handleOnDelete()}/>
            </div>
        </BaseCard>
    }

    private handleOnDelete() {
        profileService.deleteUser(this.props.id)
        if(this.props.onDelete)
            this.props.onDelete(this.props.id)
    }
}

export default  User;