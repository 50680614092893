import React, {Component} from "react";
import ClientsAdminPanel from "./ClientsAdminPanel";
import './AdminPanel.css';
import EquipmentAdminPanel from "./EquipmentAdminPanel";
import {SearchInput} from "medissima-ui-kit";
import {dashboardService} from "./dashboard.service";
import {dashboardQuery} from "./dashboard.query";
import {createInitialState, DashboardState} from "./dashboard.store";

type State = {
  dashboardState: DashboardState
}

class DashboardAdmin extends Component<any, State> {

  constructor(props: Readonly<any>) {
    super(props);

    this.state = {
      dashboardState: createInitialState()
    }
  }

  componentDidMount(): void {
    dashboardService.getDashboardStatistics()

    dashboardQuery.select()
      .subscribe(state => {
        this.setState({
          ...this.state,
          dashboardState: state
        })
      })
  }

  render() {
        return <div className={"view-container"}>
                <nav aria-label="breadcrumb" className="bread-crumb--component">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">Панель администратора</li>
                    </ol>
                </nav>
                <div className={"row"}>
                    <div className={"col col-sm-6"}>
                        <ClientsAdminPanel
                          onMaintenance={this.state.dashboardState.on_maintenance}
                          clientsStatistic={this.state.dashboardState.new_for_last_month}
                          clientsCnt={this.state.dashboardState.count}
                          underConsideration={this.state.dashboardState.under_consideration}
                          redirectToClients={() => this.props.history.push("/companies")}
                        />
                    </div>
                    <div className={"col col-sm-6"}>
                        <EquipmentAdminPanel redirectToEquipment={() =>{this.props.history.push("/equipment")}}/>
                    </div>
                </div>
        </div>
    }
}

export default DashboardAdmin