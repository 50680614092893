import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Equipment } from '../models/equipment.model';

export interface EquipmentState extends EntityState<Equipment> {}

@StoreConfig({
  name: 'equipment',
  idKey: '_id',
  resettable: true
})
export class EquipmentStore extends EntityStore<EquipmentState> {

  constructor() {
    super();
  }

}

export const equipmentStore = new EquipmentStore();

