import React, {Component} from "react";
import {ButtonComponent, Span, Weight} from "medissima-ui-kit";

class NavigationButtonGroup extends Component {

    render() {
        return <div className={"admin-navigation-buttons"}>
            <Span color={"#303030"} text={"Перейти - "} />
            <ButtonComponent text={"На обслуживании"}
                             onClick={() => {}}
                             background={"#ffffff"}
                             color={"#384868"}
                             border={"#40D094"}
                             fontWeight={Weight.BOLD}
            />
            <ButtonComponent text={"Крупные"}
                             onClick={() => {}}
                             background={"#ffffff"}
                             color={"#384868"}
                             border={"#00A2FE"}
                             fontWeight={Weight.BOLD}
            />
            <ButtonComponent text={"Обсуждение"}
                             onClick={() => {}}
                             background={"#ffffff"}
                             color={"#384868"}
                             border={"#F8B058"}
                             fontWeight={Weight.BOLD}
            />
            <ButtonComponent text={"Новые клиенты"}
                             onClick={() => {}}
                             background={"#EAF2FC"}
                             color={"#384868"}
                             fontWeight={Weight.BOLD}
            />
        </div>
    }
}

export default  NavigationButtonGroup;