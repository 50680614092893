import {ID} from '@datorama/akita';
import {ClientsStore, clientsStore} from './clients.store';
import {http} from "../../index";
import {Client, createClient} from "./client.model";
import Sort from "../../models/Sort";
import {toast} from "react-toastify";
import {paginationService} from "../pagination/pagination.service";
import {UsersFilterData} from "../equipment-items/equipment-item.model";

export class ClientsService {

  constructor(private clientsStore: ClientsStore) {
  }

  getClientsList(page: number, limit: number, sort: Sort = Sort.ASC): Promise<void> {
    clientsStore.setLoading(true)

    return http.get<Client[]>(`api/admin/users?sort=${sort}&limit=${limit}&page=${page}`)
      .then(response => {
        clientsStore.reset()

        response.data.map(it => {
          this.storeClients(it)
        })

        paginationService.setPaging(response.total, response.current, response.limit)
      })
      .finally(() => clientsStore.setLoading(false))
  }

  deleteClient(id: ID) {
    http.rdelete<boolean>(`api/admin/users/${id}`)
      .then(response => {
        if (response.data) {
          clientsStore.remove(id);
          toast("Пользователь успешно удален", {autoClose: 2000});
        }
      });
  }

  searchUsers(substring: string): Promise<void> {
    clientsStore.setLoading(true)

    return http.get<Client[]>(`api/admin/users/search?substring=${substring}`)
      .then(response => {
        clientsStore.reset()

        response.data.map(it => {
          this.storeClients(it)
        })
      })
      .finally(() => clientsStore.setLoading(false))
  }

  filterUsers(data: UsersFilterData): Promise<void> {
    clientsStore.setLoading(true)

    let url: string = "api/admin/users/filter?"

    if(data.sort) {
      url += `sort=${data.sort}&`
    }

    if(data.date) {
      url += `date=${data.date}&`
    }

    if(data.company) {
      url += `company_name=${data.company}&`
    }

    return http.get<Client[]>(url)
      .then(response => {
        clientsStore.reset()

        response.data.map(it => {
          this.storeClients(it)
        })
      })
      .finally(() => clientsStore.setLoading(false))
  }

  private storeClients(item: Client) {
    clientsStore.upsert(item.id, createClient({
      id: item.id,
      email: item.email,
      company_name: item.company_name,
      registration_date: item.registration_date
    }))
  }
}

export const clientsService = new ClientsService(clientsStore);