import { ID } from '@datorama/akita';
import {Periodicity} from "../equipment/models/equipment.model";
import Sort from "../../models/Sort";

export interface EquipmentItem {
  id: ID;
  address: string,
  category: string,
  name: string
}

export interface EquipmentFilterData {
  category?: string,
  company?: string,
  equipment?: string,
  periodicity?: Periodicity,
  sort?: Sort
}

export interface UsersFilterData {
  company?: string,
  date?: string,
  sort?: Sort
}

export function mapToPeriodicity(value?: string): Periodicity|undefined {
  switch (value) {
    case "Каждый месяц":
      return Periodicity.EVERY_MONTH;
    case "Каждый квартал":
      return Periodicity.EVERY_QUARTER;
    case "Каждые полгода":
      return Periodicity.EVERY_HALF_YEAR;
    case "Каждый год":
      return Periodicity.EVERY_YEAR;
    default:
      return undefined
  }
}

export function createEquipmentItem(params: Partial<EquipmentItem>) {
  return {
    ...params
  } as EquipmentItem;
}
