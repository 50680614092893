import { Store, StoreConfig } from '@datorama/akita';

export interface FilterState {
  equipments: FilterItem[];
  categories: FilterItem[];
  companies: FilterItem[];
  periodicity: string[]
}

export type FilterItem = { id: number, name: string }

export enum PeriodicityReadable {
  EVERY_MONTH = "Каждый месяц", EVERY_QUARTER = "Каждый квартал", EVERY_HALF_YEAR = "Каждые полгода", EVERY_YEAR = "Каждый год"
}

export function createInitialState(): FilterState {
  return {
    equipments: [],
    categories: [],
    companies: [],
    periodicity: [
      "Каждый месяц", "Каждый квартал", "Каждые полгода", "Каждый год"
    ]
  };
}

@StoreConfig({ name: 'filter' })
export class FilterStore extends Store<FilterState> {

  constructor() {
    super(createInitialState());
  }

}

export const filterStore = new FilterStore();

