import React, {Component} from "react";
import {HeadComponent} from "medissima-ui-kit";
import Users from "./components/Users";
import Equipments from "./components/Equipments";
import Maintenance from "./components/Maintenance";
import {profileService} from "./akita/profile.service";
import {profileQuery} from "./akita/profile.query";
import {createInitialState, ProfileState} from "./akita/profile.store";
import "./Profile.css"
import {ButtonComponent, PageHeading, Weight} from "medissima-ui-kit/lib";

class ProfilePage extends Component<any, ProfileState> {

    constructor(props: Readonly<any>) {
        super(props);

        this.state = createInitialState()

        profileService.loadProfileData(props.match.params.id)
    }

    componentDidMount(): void {
        profileQuery.select()
          .subscribe(data => {
              this.setState(data)
          })
    }

    render() {
        return <div className={"view-container"}>
            <nav aria-label="breadcrumb" className="bread-crumb--component">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Панель администратора</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Клиенты</li>
                </ol>
            </nav>
            <PageHeading title={this.state?.organization_data.name} onBack={() => {this.props.history.goBack()}}>
                <ButtonComponent text={"Удалить"}
                                 fontWeight={Weight.BOLD}
                                 background={"#FC5858"}
                                 onClick={() => profileService.deleteCompany(this.props.match.params.id).then(() => this.props.history.goBack())}
                />
            </PageHeading>
            <div className={"row client-profile--body"}>
                <div className={"col col-sm-6"}>
                    <Users
                       redirectToUsers={() => this.props.history.push("/access")}
                    />
                </div>
                <div className={"col col-sm-6"}>
                    <Equipments
                      redirectToEquipment={() => this.props.history.push("/equipment")}
                    />
                    <Maintenance items={this.state.reviews} />
                </div>
            </div>
        </div>
    }
}

export default ProfilePage