import React, {Component} from "react";
import "../css/Modals.css"
import {H1, H2, Paragraph, SuccessIcon, TextSize} from "medissima-ui-kit";

type Data = {
  title?: string,
  text?: string
}

class ErrorModal extends Component<Data, any> {

  render() {
    return <div className={"modal--error"}>
      <H2 text={this.props.title ?? "ОШИБКА"} color={"#FFFFFF"}/>
      <Paragraph size={TextSize.P_24} text={this.props.text}/>
    </div>
  }

}

export default ErrorModal;