import React, {Component} from "react";
import "./Filter.css"
import {filterQuery} from "./equipment-filter/filter.query";
import {filterService} from "./equipment-filter/filter.service";
import {createInitialState, FilterItem, FilterState, PeriodicityReadable} from "./equipment-filter/filter.store";
import {ButtonComponent, CalendarIcon, DropDownComponent, FilterListItem, Paragraph, TextSize} from "medissima-ui-kit";
import {findInKV, KeyValue} from "../../models/KeyValue";
import Sort from "../../models/Sort";
import {mapToPeriodicity, UsersFilterData} from "../equipment-items/equipment-item.model";
import {convertDateToFormat} from "../../date";
import Calendar from 'react-calendar';

type Data = {
  onSubmit: (data?: UsersFilterData) => void,
  onSortApply: (sort: Sort) => void
}

type State = {
  isPickerActive?: boolean,
  companies: FilterItem[],
  filterSelectedData: KeyValue[],
  selectedCompany?: string,
  selectedDate?: Date,
  selectedSort?: Sort,
  resetCompany?: boolean
}

const dateKey = "Дата регистрации";
const companyKey = "Компания";

class UsersFilter extends Component<Data, State> {

  constructor(props: Readonly<Data>) {
    super(props);

    this.state = {
      companies: [],
      filterSelectedData: []
    }
  }

  componentDidMount() {
    filterQuery.select()
      .subscribe(data => this.setState({
        ...this.state,
        companies: data.companies
      }))

    filterService.initFilterData()
  }

  render() {
    return <div className={"filter-area"}>
      <div className={"filter-area--selection"}>
        <div className={"calendar"}>
          <CalendarIcon
            fill={"#40D094"}
            onClick={() => this.setState({
              ...this.state,
              isPickerActive: !this.state.isPickerActive
            })}
          />
          <Calendar
            className={this.state.isPickerActive ? "": "hidden"}
            onChange={date => this.onDatePicked(date)}
            value={this.state.selectedDate ?? new Date()}
          />
          <Paragraph text={convertDateToFormat(this.state.selectedDate) ?? "Дата регистрации"} color={"#607088"} size={TextSize.P_18}/>
        </div>
        <div className={"filter-area--selection--other"}>
          <DropDownComponent
            id={"company--filter-area--status"}
            placeholder={"Компания"}
            items={this.state.companies.map(i => i.name)}
            searchable={true}
            onItemSelected={(_: number, value: string) => this.onCompanySelected(value)}
            reset={this.state.resetCompany}
          />
          <div className={"company--filter-area--action-buttons"}>
            <ButtonComponent text={"Применить"} background={"#40D094"} onClick={() => this.onFilterSubmit()} />
            <ButtonComponent text={"Сбросить"} background={"#FC5858"} onClick={() => this.onResetFilter()} />
          </div>
        </div>
      </div>
      <div className={"filter-area--bottom"}>
        <div className={"filter-area--selected-items"}>
          {
            this.state.filterSelectedData
              .map((i, index) => <FilterListItem
                id={index}
                text={`${i.key}: ${i.value}`}
                onRemove={i => this.onDeleteItemFromFilter(i)}
              />)
          }
        </div>
        <div className={"filter-area--sort"}>
          <DropDownComponent
            id={"company--filter-area--sort"}
            placeholder={"Сортировка"}
            items={["по убыванию", "по возрастанию"]}
            searchable={false}
            onItemSelected={(id: number, _: string) => {
              console.log(id)

              let sort: Sort;

              if(id == 0) {
                sort = Sort.ASC
              } else {
                sort = Sort.DESC
              }

              this.setState({
                ...this.state,
                selectedSort: sort
              })

              this.props.onSortApply(sort)
            }}
          />
        </div>
      </div>
    </div>
  }

  private onCompanySelected(value: string) {
    this.setState({
      ...this.state,
      selectedCompany: value,
      resetCompany: false
    })
  }

  private onDatePicked(date: Date) {
    this.setState({
      ...this.state,
      selectedDate: date,
      isPickerActive: false
    })
  }

  private onFilterSubmit() {
    const kv :KeyValue[] = []

    if(this.state.selectedDate) {
      kv.push({
        key: dateKey,
        value: convertDateToFormat(this.state.selectedDate)
      })
    }

    if(this.state.selectedCompany) {
      kv.push({
        key: companyKey,
        value: this.state.selectedCompany
      })
    }

    this.setState({
      ...this.state,
      filterSelectedData: kv
    })

    this.props.onSubmit({
      company: findInKV(companyKey, kv),
      date: findInKV(dateKey, kv),
      sort: this.state.selectedSort,
    })
  }

  private onResetFilter() {
    this.props.onSubmit(undefined)

    this.setState({
      ...this.state,
      filterSelectedData: [],
      selectedCompany: undefined,
      selectedDate: undefined,
      resetCompany: true
    })
  }

  private onDeleteItemFromFilter(index: number) {
    const filterSelectedData = this.state.filterSelectedData;
    delete filterSelectedData[index];

    this.setState({
      ...this.state,
      filterSelectedData: filterSelectedData
    })

    this.props.onSubmit({
      company: findInKV(companyKey, filterSelectedData),
      date: findInKV(dateKey, filterSelectedData),
      sort: this.state.selectedSort,
    })
  }

}

export default UsersFilter;