import { Query } from '@datorama/akita';
import { PaginationStore, PaginationState, paginationStore } from './pagination.store';

export class PaginationQuery extends Query<PaginationState> {

  constructor(protected store: PaginationStore) {
    super(store);
  }

}

export const paginationQuery = new PaginationQuery(paginationStore);