import React, {Component} from "react";
import {
    Align,
    HeadComponent,
    Loader,
    PaginationFooter,
    Paragraph,
    TableBody,
    TableHead,
    TypeTableContent
} from "medissima-ui-kit";
import {clientsService} from "./clients.service";
import {clientsQuery} from "./clients.query";
import {Nothing} from "../../models/Nothing";
import {Client} from "./client.model";
import {paginationQuery} from "../pagination/pagination.query";
import UsersFilter from "../filters/UsersFilter";
import Sort from "../../models/Sort";
import {UsersFilterData} from "../equipment-items/equipment-item.model";
import {equipmentItemService} from "../equipment-items/equipment-item.service";
import {PageHeading, SearchInput} from "medissima-ui-kit/lib";

type State = {
    clientsList: Array<Client>,
    isLoading: boolean,
    isEmpty: boolean,
    totalPages: number,
    currentPage: number,
    limit: number,
    sorting: Sort
}

class AccessControlPage extends Component<any, State> {

    constructor(props:  Readonly<Nothing>) {
        super(props);
        this.state = {
            clientsList: [],
            isLoading: true,
            isEmpty: true,
            totalPages: 0,
            currentPage: 0,
            limit: 6,
            sorting: Sort.ASC
        }
    }

    componentDidMount(): void {
        clientsQuery.selectLoading()
            .subscribe(loading => {
                this.setState({
                    ...this.state,
                    isLoading: loading
                })
            });

        clientsQuery.selectAll()
            .subscribe(items =>{
                this.setState({
                    ...this.state,
                    clientsList: items,
                    isEmpty: items.length == 0
                })
            })

        this.loadClients(0)
    }

    private loadClients(page: number, sort?: Sort) {
        clientsService.getClientsList(page, this.state.limit, sort ?? this.state.sorting)
          .then(() => {
              const paging = paginationQuery.getValue();
              this.setState({
                  ...this.state,
                  totalPages: paging.pageCount,
                  currentPage: paging.current
              })
          })
    }

    render() {
        return <div className={"view-container"}>
            <nav aria-label="breadcrumb" className="bread-crumb--component">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Панель администратора</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Настройка доступа</li>
                </ol>
            </nav>
            <PageHeading title={"Настройка доступа"} onBack={() => {this.props.history.goBack()}}>
                <SearchInput placeholder={"Поиск по Email"} onTextInput={(substring: string) => this.handleOnFormSubmit(substring)}/>
            </PageHeading>
            <div>
                <UsersFilter onSubmit={data => this.onFilterSubmit(data)} onSortApply={sort => this.onFilterSort(sort)} />
                <div style={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <Loader visible={this.state.isLoading}/>
                </div>
                {
                    this.state.isEmpty ?
                        <Paragraph className={"empty-text"} text={"Список пользователей пуст"} align={Align.CENTER}/> :
                        <table style={{border: "none"}} className="table table-bordered table-container admin-table">
                            <TableHead titles={[
                                "Название компании",
                                "Email администратора",
                                "Дата регистрации",
                                "Управление"
                            ]} />
                            <tbody className={"table-body"}>
                            {
                                this.state.clientsList.map(i => {
                                    return <tr>
                                        <TableBody type={TypeTableContent.TEXT} text={i.company_name}/>
                                        <TableBody type={TypeTableContent.TEXT} text={i.email}/>
                                        <TableBody type={TypeTableContent.TEXT} text={i.registration_date}/>
                                        <TableBody id={i.id} type={TypeTableContent.DELETE} className={"delete-type--table"} text={"Удалить"} onClick={(id: number | string) =>{this.handleOnDelete(id)}}/>
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>

                }
                {
                    this.state.totalPages > 0 ? <PaginationFooter pages={this.state.totalPages}
                                                                  current={this.state.currentPage}
                                                                  onClick={(page => this.handleOnPageClick(page))}/> : <div/>
                }
            </div>
        </div>
    }

    handleOnDelete(id: number | string){
        clientsService.deleteClient(id)
    }

    private handleOnFormSubmit(substring: string) {
        clientsService.searchUsers(substring)
          .then(() => {
              const paging = paginationQuery.getValue();
              this.setState({
                  ...this.state,
                  totalPages: paging.pageCount,
                  currentPage: paging.current
              })
          })
    }

    private handleOnPageClick(page: any) {
        this.loadClients(page)
    }

    private onFilterSort(sort: Sort) {
        this.setState({
            ...this.state,
            sorting: sort
        })

        this.loadClients(this.state.currentPage, sort)
    }

    private onFilterSubmit(data?: UsersFilterData) {
        if(!data) {
            this.loadClients(this.state.currentPage)
        } else {
            clientsService.filterUsers(data)
              .then(() => {
                  this.setState({
                      totalPages: 0
                  })
              })
        }
    }
}

export default AccessControlPage