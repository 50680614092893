import React, {Component, useState} from "react";
import {Nothing} from "../../models/Nothing";
import CategoryModal from "./ui/CategoryModal";
import EquipmentModal from "./ui/EquipmentModal";
import {CloseIcon, CloseModalIcon} from "medissima-ui-kit";
import SuccessModal from "./ui/SuccessModal";
import {createInitialState, ModalsState, ModalsStateType} from "./state/modals.store";
import {modalsQuery} from "./state/modals.query";
import "./css/Modals.css"
import ErrorModal from "./ui/ErrorModal";
import EditCategoryModal from "./ui/EditCategoryModal";

type State = {
  isOpen?: boolean,
  modal?: ModalsState
}

class ModalWindow extends Component<Nothing, State> {

  constructor(props: Readonly<Nothing>) {
    super(props);
  }

  componentDidMount(): void {
    modalsQuery.select()
      .subscribe((state: ModalsState) => {
        this.setState({
          isOpen: state.key != ModalsStateType.UNKNOWN,
          modal: state
        })
      })
  }

  render() {
    return <div className={`modal--wrapper ${this.determineVisibility()}`}>
      <div className={"modal--container"}>
        <CloseModalIcon className={"modal--wrapper--close"} onClick={() => this.handleOnClose()} />
        <div className={"modal--content"}>
          {this.determineWindow()}
        </div>
      </div>
    </div>
  }

  private determineVisibility(): string {
    const visibility = this?.state?.isOpen;
    if(visibility === undefined) {
      return ""
    } else if(visibility) {
      return "fade-in"
    } else {
      return "fade-out"
    }
  }

  private determineWindow() {
    switch (this.state?.modal?.key) {
      case ModalsStateType.ERROR:
        return <ErrorModal text={this.state.modal?.payload?.error}/>
      case ModalsStateType.SUCCESS:
        return <SuccessModal title={"ГОТОВО"} text={this.state.modal?.payload?.text}/>
      case ModalsStateType.CATEGORY:
        return <CategoryModal />
      case ModalsStateType.EQUIPMENT:
        return <EquipmentModal onSuccess={this.state?.modal?.payload?.onSuccess} />
      case ModalsStateType.EDIT:
        return <EditCategoryModal id={this.state.modal?.payload?.id}/>
      default:
        return null
    }
  }

  private handleOnClose() {
    this.setState({
      isOpen: false,
      modal: createInitialState()
    })
  }
}

export default ModalWindow;