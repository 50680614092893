import { ID } from '@datorama/akita';
import {
  createInitialState,
  PaginationStore,
  paginationStore
} from './pagination.store';

export class PaginationService {

  constructor(private paginationStore: PaginationStore) {
  }

  reset() {
    paginationStore.update(createInitialState())
  }

  setPaging(total: number, current: number, limit: number) {
    paginationStore.update({
      total: total,
      current: current,
      pageCount: parseInt((total - 1) / limit + "")
    })
  }

}

export const paginationService = new PaginationService(paginationStore);