import { ID } from '@datorama/akita';

export interface Auth {
  email: string;
  id: ID;
  name: string;
  organization: Organization;
  token: string;
}

export interface Organization {
  address: string;
  id: number;
  name: string;
  status: Status;
}

export enum Status {
  ON_SERVICE = "ON_SERVICE",
  IN_DISCUSS = "IN_DISCUSS",
  PARENT = "PARENT",
  UNKNOWN = "UNKNOWN"
}

export function createAuth(params: Partial<Auth>) {
  return {
    ...params
  } as Auth;
}
