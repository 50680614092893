import React, {Component} from "react";
import '../../css/Menu.css'
import CustomersItemMenu from "./menu_items/CustomersItemMenu";
import EquipmentItemMenu from "./menu_items/EquipmentItemMenu";
import AccessSettingItem from "./menu_items/AccessSettingItem";
import CatalogItem from "./menu_items/CatalogItem";

type Data = {

}

type State = {
    selected?: number
}

class SidebarMenu extends Component<Data, State> {

    constructor(props: Readonly<Data>){
        super(props);
        this.selectedMethod = this.selectedMethod.bind(this);
        this.state = {
            selected: undefined
        }
    }

    selectedMethod(key: number) {
        this.setState({
            selected: key
        })
    }

    render() {
        return <div className={"admin-menu--items"}>
            <CustomersItemMenu onClick={this.selectedMethod} eventKey={0} className={ ['company', 'companies'].some(i => window.location.hash.includes(i))  ? "active" : ""}/>
            <EquipmentItemMenu onClick={this.selectedMethod} eventKey={1} className={ window.location.hash.includes('equipment') ? "active" : ""}/>
            <AccessSettingItem onClick={this.selectedMethod} eventKey={2} className={ window.location.hash.includes('access') ? "active" : ""}/>
            <CatalogItem onClick={this.selectedMethod} eventKey={3} className={ ['catalog', 'category'].some(i => window.location.hash.includes(i))  ? "active" : ""}/>
        </div>
    }
}

export default SidebarMenu;