import { ID } from '@datorama/akita';
import {ProfileState, ProfileStore, profileStore} from './profile.store';
import {http} from "../../../index";
import {profileQuery} from "./profile.query";

export class ProfileService {

  constructor(private profileStore: ProfileStore) {
  }

  loadProfileData(id?: number|string) {
    profileStore.setLoading(true)

    http.get<ProfileState>(`api/admin/companies/view/${id}`)
      .then(response => {
        profileStore.update(response.data)
      })
      .then(() => profileStore.setLoading(true))
  }

  deleteCompany(id?: string|number) {
    return http.rdelete(`api/admin/companies/${id}`)
      .then(response => {
        console.log(response)
      })
  }

  deleteUser(id?: string|number) {
    http.rdelete(`api/admin/users/${id}`)
      .then(response => {
        console.log(response)
      })
  }
}

export const profileService = new ProfileService(profileStore);