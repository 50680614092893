import { ID } from '@datorama/akita';

export interface EquipmentClass {
  id: ID;
  equipmentType: string,
  client: string
}

export function createEquipmentClass(params: Partial<EquipmentClass>) {
  return {
    ...params
  } as EquipmentClass;
}
