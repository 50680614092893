import React, {Component} from "react";
import {BaseCard, ButtonComponent, H2, Weight} from "medissima-ui-kit";
import VariableList from "../../components/VariableList";
import {UserData} from "../akita/profile.store";
import User from "./User";
import {profileQuery} from "../akita/profile.query";
import {Redirect} from "react-router";

type Data = {
  redirectToUsers: () => void
}

type State = {
  typeUser: Array<string>,
  users: UserData[],
  count: number
}

class Users extends Component<Data, State> {

  constructor(props: Readonly<any>) {
    super(props);

    this.state = {
      typeUser: ["Пользователи", "Администраторы"],
      count: 5,
      users: []
    }
  }

  componentDidMount(): void {
    profileQuery.select()
      .subscribe(profile => {
        this.setState({
          ...this.state,
          users: profileQuery.getValue().users
        })
      })
  }

  render() {

    return <BaseCard className={"admin-panel-card client"}>
      <div className={"admin-panel-card--title"}>
        <H2 color={"#303030"} text={"Пользователи"}/>
        <ButtonComponent fontWeight={Weight.BOLD} background={"#72AAFC"} text={"Все пользователи"} onClick={() => this.props.redirectToUsers()}/>
      </div>
      <div className={"admin-panel-card--body"}>
        <VariableList onClick={(title: string) => this.handleOnFilterClicked(title)}
                      title={"По типу"}
                      items={this.state.typeUser}
                      background={"#EAF2FC"}
                      selectedBackground={"#a6dcff"}
                      needButton={false}
        />
        <hr/>
        <div className={"client--user-list"}>
          {
            this.state.users
              ?.slice(0, this.state.count)
              ?.map(user => {
                return <User
                  isAdmin={user.is_admin}
                  id={user.id}
                  name={user.name}
                  email={user.email}
                  onDelete={id => this.handleOnDelete(id)}
                />
              })
          }
          <ButtonComponent className={this.state.count > (this.state.users?.length ?? 0) ? "hidden" : ""} background={"#FFFFFF"}
                           color={"#72AAFC"} text={"Показать еще"} border={"#72AAFC"}
                           onClick={() => this.handleOnMoreClicked()}/>
        </div>
      </div>
    </BaseCard>
  }

  private handleOnMoreClicked() {
    this.setState({
      ...this.state,
      count: this.state.count + 5
    })
  }

  private handleOnFilterClicked(title: string) {
    let users: UserData[]

    if(title == "Пользователи") {
      users = profileQuery.getValue().users.filter(i => !i.is_admin)
    } else {
      users = profileQuery.getValue().users.filter(i => i.is_admin)
    }

    this.setState({
      ...this.state,
      users: users
    })
  }

  private handleOnDelete(id?: string | number) {
    const users = this.state.users;
    const userById = users.find(i => i.id == id)
    if(userById !== undefined) {
      delete users[users.indexOf(userById)]
    }

    this.setState({
      ...this.state,
      users: users
    })
  }
}

export default Users;