import React, {Component} from "react";
import {BaseCard, CalendarIcon, H2, ListItem} from "medissima-ui-kit";
import {ReviewData} from "../akita/profile.store";

type Data = {
    items: ReviewData[]
}

class Maintenance extends Component<Data> {

    render() {
        return <BaseCard className={"maintenance"}>
            <H2 text={"Ближайшие ТО"}/>
            <div className={"maintenance-period"}>
                <CalendarIcon/>
                <a  style={{color: "#808080"}}>Выбрать период</a>
            </div>
            <ul className={"maintenance-list card-list"}>
                {
                    this.props.items.map(i => {
                        return <ListItem
                                className={"maintenance-list-item"}
                                text={i.name}
                                date={i.date}
                                background={"#EAF2FC"}
                        />
                    })
                }
            </ul>
        </BaseCard>
    }
}

export default Maintenance;