import { Store, StoreConfig } from '@datorama/akita';

export interface DashboardState {
  count: number,
  new_for_last_month: number,
  on_maintenance: number,
  under_consideration: number
}

export function createInitialState(): DashboardState {
  return {
    count: 0,
    new_for_last_month: 0,
    on_maintenance: 0,
    under_consideration: 0
  };
}

@StoreConfig({ name: 'dashboard', resettable: true})
export class DashboardStore extends Store<DashboardState> {

  constructor() {
    super(createInitialState());
  }

}

export const dashboardStore = new DashboardStore();

