import React, {Component} from "react";
import {Card, CardHeader} from "reactstrap";
import {Link} from "react-router-dom";

type Data = {
    eventKey: number,
    onClick: (key: number) => void,
    className?: string
}

class AccessSettingItem extends Component<Data> {

    render() {
        // return <div>
        //
        // </div>
        return <Card eventKey={`${this.props.eventKey}`}>
            <CardHeader className={`menu-item ${this.props.className}`}>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M47.2985 38.4207L28.6804 20.6743C31.437 14.7235 30.5786 8.69846 26.3294 4.44924C23.3334 1.45326 19.1806 -0.176036 14.9511 0.0151206C10.6563 0.194665 6.75455 2.11427 3.96402 5.41664C-1.10342 11.4175 -0.683588 20.4546 4.9207 25.9901C8.83227 29.8543 14.3454 31.137 19.763 29.4711L21.9024 31.6525C22.0703 31.8231 22.2999 31.9204 22.5402 31.9204H26.1373V34.6002C26.1373 35.0942 26.5375 35.4935 27.0306 35.4935H29.7103V38.1732C29.7103 38.6672 30.1105 39.0665 30.6036 39.0665H34.1766V42.6395C34.1766 43.1335 34.5768 43.5328 35.0699 43.5328H36.8564V45.1192C36.8564 45.9812 36.9305 46.4617 37.6094 47.1406C38.1061 47.6391 38.7662 47.9419 39.5362 47.999H46.6822C47.1753 47.999 47.5755 47.5998 47.5755 47.1058V39.0665C47.5755 38.8226 47.4754 38.5895 47.2985 38.4207ZM45.7889 46.2134L39.6049 46.2161C39.3289 46.1956 39.069 46.0759 38.8734 45.8793C38.6429 45.6489 38.6429 45.6489 38.6429 45.1201V42.6404C38.6429 42.1464 38.2427 41.7471 37.7496 41.7471H35.9631V38.1741C35.9631 37.6801 35.563 37.2809 35.0699 37.2809H31.4969V34.6011C31.4969 34.1071 31.0967 33.7078 30.6036 33.7078H27.9238V31.0281C27.9238 30.5341 27.5236 30.1348 27.0306 30.1348H22.9153L20.6518 27.8257C20.4115 27.5801 20.0498 27.4944 19.7237 27.6069C14.7885 29.3041 9.72463 28.225 6.17662 24.7208C1.23155 19.8338 0.859065 11.8624 5.32892 6.56983C7.79341 3.65246 11.2369 1.95885 15.0252 1.79985C18.7671 1.6471 22.4223 3.06916 25.0654 5.71231C28.9412 9.58904 29.5719 14.9673 26.7948 20.4698C26.617 20.8208 26.6911 21.2469 26.9761 21.5185L45.7881 39.4506V46.2134H45.7889Z" fill="#1AA8FF"/>
                    <path d="M8.0122 7.60673C7.00014 8.61968 6.44275 9.96582 6.44275 11.3968C6.44275 12.8278 7.00014 14.1739 8.0122 15.186C9.02426 16.199 10.3704 16.7563 11.8023 16.7563C13.2342 16.7563 14.5794 16.199 15.5924 15.186C16.6044 14.1739 17.1618 12.8278 17.1618 11.3968C17.1618 9.96582 16.6044 8.61968 15.5924 7.60673C13.5022 5.51651 10.1015 5.51651 8.0122 7.60673ZM14.3284 13.9229C12.9778 15.2735 10.625 15.2735 9.27526 13.9229C8.60086 13.2476 8.22926 12.3508 8.22926 11.3968C8.22926 10.4428 8.60086 9.54509 9.27526 8.86979C9.972 8.17394 10.8867 7.82557 11.8023 7.82557C12.7179 7.82557 13.6326 8.17394 14.3293 8.86979C15.0037 9.54509 15.3753 10.4428 15.3753 11.3968C15.3753 12.3508 15.0037 13.2476 14.3284 13.9229Z" fill="#1AA8FF"/>
                </svg>
                <div onClick={event => this.handleOnClick(event)} className={"menu-item-title"}>
                    <Link to={"/access"}>Настройка доступа</Link>
                </div>
            </CardHeader>
        </Card>
    }

    private handleOnClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        event.preventDefault();
        this.props.onClick(this.props.eventKey);
    }
}

export default AccessSettingItem;