import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Auth } from './auth.model';

export interface AuthState extends EntityState<Auth> {}

@StoreConfig({
  name: 'auth',
  idKey: '_id',
  resettable: true
})
export class AuthStore extends EntityStore<AuthState> {

  constructor() {
    super();
  }

}

export const authStore = new AuthStore();

