import { QueryEntity } from '@datorama/akita';
import { EquipmentClassesStore, EquipmentClassesState, equipmentClassesStore } from './equipment-classes.store';

export class EquipmentClassesQuery extends QueryEntity<EquipmentClassesState> {

  constructor(protected store: EquipmentClassesStore) {
    super(store);
  }

}

export const equipmentClassesQuery = new EquipmentClassesQuery(equipmentClassesStore);
