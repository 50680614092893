import { Store, StoreConfig } from '@datorama/akita';

export interface PaginationState {
  current: number;
  total: number;
  pageCount: number;
}

export function createInitialState(): PaginationState {
  return {
    current: 0,
    total: 0,
    pageCount: 0
  };
}

@StoreConfig({ name: 'equipment-pagination' })
export class PaginationStore extends Store<PaginationState> {

  constructor() {
    super(createInitialState());
  }

}

export const paginationStore = new PaginationStore();

