import {EquipmentItemStore, equipmentItemStore} from './equipment-item.store';
import {http} from "../../index";
import Sort from "../../models/Sort";
import {paginationService} from "../pagination/pagination.service";
import {EquipmentFilterData} from "./equipment-item.model";

export class EquipmentItemService {

  constructor(private equipmentItemStore: EquipmentItemStore) {
  }

  loadItems(page: number = 0, limit: number = 10, sort: Sort = Sort.ASC): Promise<void> {
    equipmentItemStore.setLoading(true)

    return http.get(`api/admin/equipment-item?page=${page}&limit=${limit}&sort=${sort}`)
      .then(response => {
        equipmentItemStore.reset()
        response.data.map(i => {
          equipmentItemStore.upsert(i.id, i)
        })

        paginationService.setPaging(response.total, response.current, response.limit)
      })
      .then(() => {
        equipmentItemStore.setLoading(false)
      })
  }

  searchItems(text: string): Promise<void> {
    equipmentItemStore.setLoading(true)

    return http.get(`api/admin/equipment-item/search?text=${text}`)
      .then(response => {
        equipmentItemStore.reset()
        response.data.map(i => {
          equipmentItemStore.upsert(i.id, i)
        })
        console.log(response)

        paginationService.setPaging(response.total, response.current, response.limit)
      })
      .then(() => {
        equipmentItemStore.setLoading(false)
      })
  }

  filterItems(data: EquipmentFilterData): Promise<void> {
    equipmentItemStore.setLoading(true)

    let url: string = "api/admin/equipment-item/filter?"

    if(data.periodicity) {
      url += `periodicity=${data.periodicity}&`
    }

    if(data.sort) {
      url += `sort=${data.sort}&`
    }

    if(data.category) {
      url += `category=${data.category}&`
    }

    if(data.equipment) {
      url += `equipment=${data.equipment}&`
    }

    if(data.company) {
      url += `company=${data.company}&`
    }

    return http.get(url)
      .then(response => {

        equipmentItemStore.reset()
        response.data.map(i => {
          equipmentItemStore.upsert(i.id, i)
        })

        paginationService.setPaging(response.total, response.current, response.limit)
      })
      .then(() => {
        equipmentItemStore.setLoading(false)
      })
  }

}

export const equipmentItemService = new EquipmentItemService(equipmentItemStore);