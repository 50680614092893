import React, {Component} from 'react';
import "../../css/Equipment.css";
import {
  Align,
  Center,
  EquipmentListItem,
  HeadComponent,
  Loader,
  PaginationFooter,
  Paragraph
} from "medissima-ui-kit";
import {Category} from "./models/category.model";
import {categoryQuery} from "./queries/category.query";
import {equipmentService} from "./services/equipment.service";
import {equipmentQuery} from "./queries/equipment.query";
import {Equipment} from "./models/equipment.model";
import {paginationService} from "../pagination/pagination.service";
import {paginationQuery} from "../pagination/pagination.query";
import {ModalsStateType, modalsStore} from "../modals/state/modals.store";
import {ID} from '@datorama/akita';
import {ButtonComponent, PageHeading, Weight} from "medissima-ui-kit/lib";

type State = {
  category?: Category,
  equipment: Equipment[]
  totalPages: number,
  currentPage: number,
  limit: number,
  isLoading: boolean,
  isEmpty: boolean
}

class EquipmentsPage extends Component<any, State> {

  constructor(props: Readonly<any>) {
    super(props);

    this.state = {
      isLoading: true,
      isEmpty: true,
      totalPages: 0,
      currentPage: 0,
      limit: 10,
      equipment: []
    }
  }

  componentWillUnmount(): void {
    paginationService.reset()
  }

  componentDidMount(): void {
    const categoryId = this.props.match.params.id;

    equipmentQuery.selectLoading()
      .subscribe(loading => {
        this.setState({
          ...this.state,
          isLoading: loading
        })
      })

    categoryQuery.selectEntity(categoryId)
      .subscribe(item => {
        console.log("categoryQuery", item);
        this.setState({
          ...this.state,
          category: item
        })
      })

    equipmentQuery.selectAll()
      .subscribe(items => {
        console.log("equipmentQuery", items);
        this.setState({
          ...this.state,
          equipment: items,
          isEmpty: items.length == 0
        })
      })

    this.loadEquipment(0)
  }

  private loadEquipment(page: number) {
    const categoryId = this.props.match.params.id;

    equipmentService.loadEquipmentByCategory(categoryId, page, this.state.limit)
      .then(() => {
        const paging = paginationQuery.getValue();
        this.setState({
          ...this.state,
          totalPages: paging.pageCount,
          currentPage: paging.current
        })
      })
  }

  render() {
    return <div className={"equipment-catalog--page"}>
      <nav aria-label="breadcrumb" className="bread-crumb--component">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="#">Панель администратора</a></li>
          <li className="breadcrumb-item"><a href="/#/catalog">Каталог оборудования</a></li>
          <li className="breadcrumb-item active" aria-current="page">{this.state?.category?.name}</li>
        </ol>
      </nav>
      <PageHeading title={this.state?.category?.name} onBack={() => {this.props.history.goBack()}}>
        <ButtonComponent text={"Добавить оборудование"}
                         onClick={() => this.handleOnClick()}
                         fontWeight={Weight.BOLD}
                         background={"#40D094"}
        />
      </PageHeading>
      <div>
        <Center>
          <Loader visible={this.state.isLoading}/>
        </Center>
        {
          this.state.isEmpty ?
            <Paragraph className={"empty-text"} text={"Список оборудования пуст"} align={Align.CENTER}/> : null
        }
      </div>
      <div className={"equipment-category--body"}>
        {
          this.state.equipment.map(i => {
            return <EquipmentListItem
              id={i.id}
              name={i.name}
              category={i.category}
              onDelete={(id: ID) => this.handleOnDelete(id)}
            />
          })
        }
      </div>
      {
        this.state.totalPages > 0 ? <PaginationFooter pages={this.state.totalPages} current={this.state.currentPage}
                                                      onClick={(page => this.handleOnPageClick(page))}/> : null
      }

    </div>
  }

  private handleOnClick() {
    modalsStore.update({
      key: ModalsStateType.EQUIPMENT,
      payload: {
        onSuccess: () => { this.loadEquipment(this.state.currentPage) },
      }
    })
  }

  private handleOnPageClick(page: number) {
    this.loadEquipment(page)
  }

  private handleOnDelete(id: ID) {
    equipmentService.deleteItem(id)
  }
}

export default EquipmentsPage;