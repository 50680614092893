import React, {Component} from "react";
import EquipmentVariety from "./EquipmentVariety";
import NavigationButtonGroup from "./NavigationButtonGroup";
import {BaseCard, Weight} from "medissima-ui-kit";
import {H2} from "medissima-ui-kit";
import {ButtonComponent} from "medissima-ui-kit";

type Data = {
    redirectToEquipment: () => void
}

class EquipmentAdminPanel extends Component<Data> {

    render() {
        return <BaseCard className={"admin-panel-card equipment"}>
            <div className={"admin-panel-card--title"}>
                <H2 color={"#303030"} text={"Оборудование"}/>
                <ButtonComponent fontWeight={Weight.BOLD} background={"#72AAFC"} text={"Смотреть все"} onClick={() => this.props.redirectToEquipment()}/>
            </div>
            <div className={"admin-panel-card--body"}>
                <EquipmentVariety background={"#EAF2FC"} title={"По типу"} />
                <hr/>
                <NavigationButtonGroup/>
                <hr/>
                <EquipmentVariety title={"По клиентам"} byClient={true}/>
            </div>
        </BaseCard>
    }

}

export default EquipmentAdminPanel;