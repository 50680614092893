import { ID } from '@datorama/akita';

export interface Client {
  id: ID;
  company_name: string;
  email: string;
  registration_date: string;
}

export function createClient(params: Partial<Client>) {
  return {
    ...params
  } as Client;
}
