import { QueryEntity } from '@datorama/akita';
import { EquipmentItemStore, EquipmentItemState, equipmentItemStore } from './equipment-item.store';

export class EquipmentItemQuery extends QueryEntity<EquipmentItemState> {

  constructor(protected store: EquipmentItemStore) {
    super(store);
  }

}

export const equipmentItemQuery = new EquipmentItemQuery(equipmentItemStore);
