import React, {Component} from 'react';
import "../../css/Equipment.css"
import {Align, CategoryCard, Loader, Paragraph} from "medissima-ui-kit";
import {ModalsStateType, modalsStore} from "../modals/state/modals.store";
import {Nothing} from "../../models/Nothing";
import {Category} from "./models/category.model";
import {categoryQuery} from "./queries/category.query";
import {categoryService} from "./services/category.service";
import {ID} from "@datorama/akita";
import {ButtonComponent, PageHeading, Weight} from "medissima-ui-kit/lib";

type State = {
    categories: Category[],
    isLoading: boolean,
    isEmpty: boolean
}

class CategoriesCatalog extends Component<any, State> {

    constructor(props: Readonly<Nothing>) {
        super(props);

        this.state = {
            categories: [],
            isLoading: true,
            isEmpty: false
        }
    }

    componentDidMount(): void {
        categoryService.loadCategory()

        categoryQuery.selectLoading()
          .subscribe(l => {
              this.setState({
                ...this.state,
                  isLoading: l,
              })
          })

        categoryQuery.selectAll()
          .subscribe(cats => {
              this.setState({
                  ...this.state,
                  categories: cats,
                  isEmpty: cats.length == 0
              })
          })
    }

    render(){
        return <div className={"equipment-catalog--page"}>
            <nav aria-label="breadcrumb" className="bread-crumb--component">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Панель администратора</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Каталог оборудования</li>
                </ol>
            </nav>
            <PageHeading title={"Каталог оборудования"} onBack={() => this.props.history.goBack()}>
                <ButtonComponent text={"Добавить категорию"}
                                 onClick={() => this.handleOnClick()}
                                 fontWeight={Weight.BOLD}
                                 background={"#40D094"}
                />
            </PageHeading>
            <div style={{
                display: "flex",
                justifyContent: "center"
            }}>
                <Loader visible={this.state.isLoading} />
            </div>
            <div className={"equipment-catalog--body"}>
                {
                    this.state.isEmpty ? <Paragraph className={"empty-text"} text={"Список категорий пуст"} align={Align.CENTER}/> : null
                }
                {
                    this.state.categories.map(item => {
                        return <CategoryCard
                          id={item.id}
                          photo={item.thumb.url}
                          category={item.name}
                          link={`/#/category/${item.id}`}
                          onDelete={(id: ID) => this.handleOnDelete(id)}
                          onClick={(id: ID) => this.handleOnEditModal(id)}
                        />
                    })
                }
            </div>
        </div>
    }

    private handleOnEditModal(id: ID){
        modalsStore.update({
            key: ModalsStateType.EDIT,
            payload: {
                id: id
            }
        })
}

    private handleOnClick() {
        modalsStore.update({
            key: ModalsStateType.CATEGORY
        })
    }

    private handleOnDelete(id: ID) {
        categoryService.deleteCategory(id)
    }
}

export default CategoriesCatalog;