import React, {Component} from "react";
import "./Filter.css"
import {filterQuery} from "./equipment-filter/filter.query";
import {filterService} from "./equipment-filter/filter.service";
import {createInitialState, FilterState, PeriodicityReadable} from "./equipment-filter/filter.store";
import {ButtonComponent, DropDownComponent, FilterListItem, Paragraph, TextSize} from "medissima-ui-kit";
import {findInKV, KeyValue} from "../../models/KeyValue";
import Sort from "../../models/Sort";
import {EquipmentFilterData, mapToPeriodicity} from "../equipment-items/equipment-item.model";

type Data = {
  onSubmit: (data?: EquipmentFilterData) => void,
  onSortApply: (sort: Sort) => void
}

type State = {
  filterData: FilterState,
  filterSelectedData: KeyValue[],
  selectedCompany?: string,
  selectedCategory?: string,
  selectedPeriodicity?: string,
  selectedEquipment?: string,
  selectedSort?: Sort,
  resetCompany?: boolean,
  resetPeriodicity?: boolean,
  resetCategory?: boolean
  resetEquipment?: boolean
  resetSort?:boolean
}

const categoryKey = "Категория";
const periodKey = "Периодичность";
const equipKey = "Оборудование";
const companyKey = "Компания";

class EquipmentFilter extends Component<Data, State> {


  constructor(props: Readonly<Data>) {
    super(props);

    this.state = {
      filterData: createInitialState(),
      filterSelectedData: []
    }
  }

  componentDidMount() {
    filterQuery.select()
      .subscribe(data => this.setState({
        ...this.state,
        filterData: data
      }))

    filterService.initFilterData()
  }

  render() {
    return <div className={"filter-area"}>
      <div className={"filter-area--selection"}>
        <div className={"calendar"}/>
        <div className={"filter-area--selection--other"}>
          <DropDownComponent
            id={"company--filter-area--status"}
            placeholder={"Компания"}
            items={this.state.filterData.companies.map(i => i.name)}
            searchable={true}
            onItemSelected={(_: number, value: string) => this.onCompanySelected(value)}
            reset={this.state.resetCompany}
          />
          <DropDownComponent
            id={"company--filter-area--equipment-count"}
            placeholder={"Категория"}
            items={this.state.filterData.categories.map(i => i.name)}
            searchable={true}
            onItemSelected={(_: number, value: string) => this.onCategorySelected(value)}
            reset={this.state.resetCategory}
          />
          <DropDownComponent
            id={"company--filter-area--equipment-count"}
            placeholder={"Оборудование"}
            items={this.state.filterData.equipments.map(i => i.name)}
            searchable={true}
            onItemSelected={(_: number, value: string) => this.onEquipmentSelected(value)}
            reset={this.state.resetEquipment}
          />
          <DropDownComponent
            id={"company--filter-area--equipment-count"}
            placeholder={"Периодичность"}
            items={this.state.filterData.periodicity.map(i => i.toString())}
            searchable={false}
            onItemSelected={(_: number, value: string) => this.onPeriodicitySelected(value)}
            reset={this.state.resetPeriodicity}
          />
          <div className={"company--filter-area--action-buttons"}>
            <ButtonComponent text={"Применить"} background={"#40D094"} onClick={() => this.onFilterSubmit()} />
            <ButtonComponent text={"Сбросить"} background={"#FC5858"} onClick={() => this.onResetFilter()} />
          </div>
        </div>
      </div>
      <div className={"filter-area--bottom"}>
        <div className={"filter-area--selected-items"}>
          {
            this.state.filterSelectedData
              .map((i, index) => <FilterListItem
                id={index}
                text={`${i.key}: ${i.value}`}
                onRemove={i => this.onDeleteItemFromFilter(i)}
              />)
          }
        </div>
        <div className={"filter-area--sort"}>
          <DropDownComponent
            id={"company--filter-area--sort"}
            placeholder={"Сортировка"}
            items={["по убыванию", "по возрастанию"]}
            searchable={false}
            reset={this.state.resetSort}
            onItemSelected={(id: number, _: string) => {
              console.log(id)
              this.setState({
                resetSort: false
              })

              let sort: Sort;

              if(id == 0) {
                sort = Sort.ASC
              } else {
                sort = Sort.DESC
              }

              this.setState({
                ...this.state,
                selectedSort: sort
              })

              this.props.onSortApply(sort)
            }}
          />
        </div>
      </div>
    </div>
  }

  private onCompanySelected(value: string) {
    this.setState({
      ...this.state,
      selectedCompany: value,
      resetCompany: false
    })
  }

  private onCategorySelected(value: string) {
    this.setState({
      ...this.state,
      selectedCategory: value,
      resetCategory: false
    })
  }

  private onPeriodicitySelected(value: string) {
    this.setState({
      ...this.state,
      selectedPeriodicity: value,
      resetPeriodicity: false
    })
  }

  private onEquipmentSelected(value: string) {
    this.setState({
      ...this.state,
      selectedEquipment: value,
      resetEquipment: false
    })
  }

  private onFilterSubmit() {
    const kv :KeyValue[] = []

    if(this.state.selectedCategory) {
      kv.push({
        key: categoryKey,
        value: this.state.selectedCategory
      })
    }

    if(this.state.selectedPeriodicity) {
      kv.push({
        key: periodKey,
        value: this.state.selectedPeriodicity
      })
    }


    if(this.state.selectedEquipment) {
      kv.push({
        key: equipKey,
        value: this.state.selectedEquipment
      })
    }


    if(this.state.selectedCompany) {
      kv.push({
        key: companyKey,
        value: this.state.selectedCompany
      })
    }

    this.setState({
      ...this.state,
      filterSelectedData: kv
    })

    this.props.onSubmit({
      company: findInKV(companyKey, kv),
      equipment: findInKV(equipKey, kv),
      category: findInKV(categoryKey, kv),
      sort: this.state.selectedSort,
      periodicity: mapToPeriodicity(findInKV(periodKey, kv))
    })
  }

  private onResetFilter() {
    this.props.onSubmit(undefined)

    this.setState({
      ...this.state,
      filterSelectedData: [],
      selectedCompany: undefined,
      selectedCategory: undefined,
      selectedPeriodicity: undefined,
      selectedEquipment: undefined,
      resetCompany: true,
      resetPeriodicity: true,
      resetCategory: true,
      resetEquipment: true,
      resetSort: true
    })
  }

  private onDeleteItemFromFilter(index: number) {
    const filterSelectedData = this.state.filterSelectedData;
    delete filterSelectedData[index];

    this.setState({
      ...this.state,
      filterSelectedData: filterSelectedData
    })

    this.props.onSubmit({
      company: findInKV(companyKey, filterSelectedData),
      equipment: findInKV(equipKey, filterSelectedData),
      category: findInKV(categoryKey, filterSelectedData),
      sort: this.state.selectedSort,
      periodicity: mapToPeriodicity(findInKV(periodKey, filterSelectedData))
    })
  }
}

export default EquipmentFilter;