import React, {Component} from "react";
import "../css/Modals.css"
import {ButtonComponent, H1, StyledFileInput, TextInput} from "medissima-ui-kit";
import {fileService, UploadData} from "../../common/file.service";
import {Nothing} from "../../../models/Nothing";
import {categoryService} from "../../equipment/services/category.service";
import {PhotoArea} from "medissima-ui-kit/lib";

type State = {
  uploadedFile?: UploadData,
  categoryName?: string,
  error?: string,
  urlName?: string,
  fileName: string
}

class CategoryModal extends Component<Nothing, State> {
  constructor(props: Readonly<Nothing>) {
    super(props);
    this.state = {
      fileName: ""
    }
  }

  render() {
    return <div className={"base-modal modal--category"}>
      <H1 text={"Добавить категорию"} />
      <TextInput id={"add-category"}
                 placeholder={"название категории"}
                 onInputChange={value => {this.setState({...this.state, categoryName: value})}}
                 error={this.state?.error}
      />
      {this.state?.urlName !== undefined ? <PhotoArea photo={this.state.urlName}/> : null}
      <StyledFileInput text={this.state.fileName !== "" ? this.state.fileName : "Загрузить фотографию"} id={"category-file"} onFileChanged={(file?: File|null) => this.onFileUploaded(file)} />
      <ButtonComponent background={"#40D094"} text={"Сохранить"} onClick={() => this.onFormSubmit()}/>
    </div>
  }

  private onFileUploaded(file?: File|null) {
    if(file !== undefined && file !== null) {
      fileService.uploadFile(file)
        .then(data => {
          this.setState({
            uploadedFile: data
          })
        })
    }
    this.setState({
      ...this.state,
      urlName: URL.createObjectURL(file),
      fileName: file?.name ?? "Загрузить фотографию"
    })
  }

  private onFormSubmit() {
    if(this.state?.categoryName !== undefined ) {
      categoryService.addCategory({
        name: this.state.categoryName,
        thumbnail: {
          url: this.state.uploadedFile?.file_url ?? "",
          file_name: this.state.uploadedFile?.file_name ?? ""
        }
      })
    } else {
      this.setState({
        error: "Введите название категории"
      })
    }


  }
}

export default CategoryModal;