import {ID} from '@datorama/akita';
import {Status} from "../auth/auth.model";

export interface Company {
  id: ID;
  address: string,
  administrator_email: string,
  equipment_count: number,
  maintenance_expired: number,
  maintenance_this_month: number,
  name: string,
  status: Status
}

export interface CompanyFilterData {
  date?: Date;
  status?: string;
  equipmentCount?: number;
}

export function mapCompanyStatuses(): string[] {
  return Object
    .keys(Status)
    .map(i => i as Status)
    .map(i => mapStatusToTitle(i))
}

export function mapStatusToTitle(status?: Status): string {
  switch (status) {
    case Status.IN_DISCUSS:
      return "В обсуждении";
    case Status.ON_SERVICE:
      return "На обслуживании";
    case Status.PARENT:
      return "Управляющая компания"
    default:
      return "Неизвестен"
  }
}

export function getCompanyStatus(statusName?: string): Status {
  let status: Status;

  switch (statusName) {
    case "В обсуждении":
      status = Status.IN_DISCUSS;
      break;
    case "На обслуживании":
      status = Status.ON_SERVICE;
      break;
    case "Управляющая компания":
      status = Status.PARENT;
      break;
    default:
      status = Status.UNKNOWN
  }

  return status
}

export function createCompany(params: Partial<Company>) {
  return {
    ...params
  } as Company;
}