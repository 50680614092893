import { ID } from '@datorama/akita';
import {DashboardState, DashboardStore, dashboardStore} from './dashboard.store';
import {http} from "../../index";

export class DashboardService {

  constructor(private dashboardStore: DashboardStore) {
  }

  getDashboardStatistics() {
    http.get<DashboardState>("api/admin/stat")
      .then(response => {
        dashboardStore.update(response.data)
      })
  }

}

export const dashboardService = new DashboardService(dashboardStore);