import {ID} from '@datorama/akita';
import {CategoryStore, categoryStore} from '../stores/category.store';
import {AddCategory, Category} from "../models/category.model";
import {http} from "../../../index";
import {ModalsStateType, modalsStore} from "../../modals/state/modals.store";
import {categoryQuery} from "../queries/category.query";
import {ResponseWrapper} from "medissima-http-client/lib";
import {Nothing} from "../../../models/Nothing";

export class CategoryService {

  constructor(private categoryStore: CategoryStore) {
  }

  loadCategory() {
    categoryStore.setLoading(true)

    http.get<Category[]>("api/admin/categories")
      .then(response => {
        response.data.forEach(i => {
          categoryStore.upsert(i.id, i)
        })
      })
      .catch(reason => {
        modalsStore.update({
          key: ModalsStateType.ERROR,
          payload: {
            error: (reason as ResponseWrapper<Nothing>).message
          }
        })
      })
      .finally(() => {
        categoryStore.setLoading(false)
      })
  }

  addCategory(data: AddCategory): Promise<void> {
    return http.post<Category[]>("api/admin/categories", data)
      .then(response => {
        response.data.forEach(i => {
          categoryStore.upsert(i.id, i)
        })
      })
      .then(() => {
        modalsStore.update({
          key: ModalsStateType.SUCCESS,
          payload: {
            text: "Категория успешно создана"
          }
        })
      })
      .catch(reason => {
        modalsStore.update({
          key: ModalsStateType.ERROR,
          payload: {
            error: (reason as ResponseWrapper<Nothing>).message
          }
        })
      })
      .finally(() => {
        categoryStore.setLoading(false)
      })
  }

  updateCategory(id: ID, data: AddCategory){
    http.put<Category[]>(`api/admin/categories/${id}`, data)
      .then(response => {
        response.data.forEach(i => {
          categoryStore.upsert(i.id, i)
        })
      })
      .then(response => {
      modalsStore.update({
        key: ModalsStateType.SUCCESS,
        payload: {
          text: "Настройки категории успешно сохранены"
        }
      })
    })
      .catch(reason => {
        modalsStore.update({
          key: ModalsStateType.ERROR,
          payload: {
            error: (reason as ResponseWrapper<Nothing>).message
          }
        })
      })
      .finally(() => {
        categoryStore.setLoading(false)
      })
  }

  deleteCategory(id: ID) {
    http.rdelete(`api/admin/categories/${id}`)
      .then(response => {
        categoryStore.reset()
        response.data.forEach(i => {
          categoryStore.upsert(i.id, i)
        })
      })
      .catch(reason => {
        modalsStore.update({
          key: ModalsStateType.ERROR,
          payload: {
            error: (reason as ResponseWrapper<Nothing>).message
          }
        })
      })
      .finally(() => {
        categoryStore.setLoading(false)
      })
  }

}

export const categoryService = new CategoryService(categoryStore);