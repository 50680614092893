import React, {Component} from "react";
import NavigationButtonGroup from "./NavigationButtonGroup";
import {Weight,
    BaseCard,
    ButtonComponent,
    H2, Span,
    GradientLinear,
    Paragraph,
    PeopleIcon,
    SearchInput} from "medissima-ui-kit";
import {CircularProgressbarWithChildren} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import {GradientRadial} from "medissima-ui-kit/lib";

type Data = {
    clientsCnt?: number,
    underConsideration: number,
    clientsStatistic?: number,
    onMaintenance: number,
    redirectToClients: () => void
}

class ClientsAdminPanel extends Component<Data> {

    render() {
        return <BaseCard className={"admin-panel-card access"}>
            <div className={"admin-panel-card--title"}>
                <H2 color={"#303030"} text={"Клиенты"}/>
                <Span color={"#303030"} text={`(${this.props.clientsCnt})`}/>
            </div>
            <div className={"admin-panel-card--body"}>
                <div className={"progress-bar--admin"}>
                    <div className={"on-maintenance"}>
                        <Span text={this.props.onMaintenance} color={"#40D094"}/>
                        <Paragraph text={"На обслуживании"} color={"#40D094"}/>
                    </div>
                    <div style={{
                        width: "240px",
                        margin: "25px"


                    }}>
                        <GradientLinear id={"progressbar_admin_path"} startColor={"#41D194"} endColor={"#62E898"}/>
                        <GradientRadial id={"progressbar_admin_trail"} startColor={"#FFFFFF"} endColor={"#FFB800"} startOffset={"95%"} endOffset={"95%"} />
                        <CircularProgressbarWithChildren
                            value={this.props.onMaintenance}
                            className={"progressbar--circular-admin"}
                            strokeWidth={25}
                            counterClockwise={true}
                            maxValue={this.props.underConsideration + this.props.onMaintenance}
                            styles={{
                                path: {
                                    strokeLinecap: 'butt',
                                    transition: 'stroke-dashoffset 0.5s ease 0s',
                                    transform: 'rotate(0.25turn)',
                                    transformOrigin: 'center center',

                                },
                                trail: {
                                    strokeLinecap: 'butt',
                                    transform: 'rotate(0.25turn)',
                                    transformOrigin: 'center center'
                                }
                            }}
                        >
                            <PeopleIcon/>
                        </CircularProgressbarWithChildren>
                    </div>
                    <div className={"pending"}>
                        <Span color={"#FFB800"} text={this.props.underConsideration}/>
                        <Paragraph text={"В ожидании"} color={"#FFB800"}/>
                    </div>
                </div>
                <div className={"access-statistics"}>
                    <p style={{
                        color: "#303030",
                        fontSize: "36px"

                    }}><span style={{color: "#40D094", fontWeight: "bold"}}>+ {this.props.clientsStatistic}</span> за последние 7 дней</p>
                    <hr/>
                </div>
                <div className={"admin-panel-card--search"}>
                    <ButtonComponent background={"#72AAFC"} fontWeight={Weight.BOLD} text={"Список клиентов"} onClick={() => this.props.redirectToClients() }/>
                    <SearchInput placeholder={"Поиск по названию организации"} onTextInput={text => {}}/>
                </div>
                <hr/>
                <NavigationButtonGroup/>
            </div>
        </BaseCard>
    }
}

export default ClientsAdminPanel;