import React, {Component} from "react";
import {ButtonComponent, ListItem, H4} from "medissima-ui-kit";
import {equipmentClassesQuery} from "./equipment_class/equipment-classes.query";
import {EquipmentClass} from "./equipment_class/equipment-class.model";
import {equipmentClassesService} from "./equipment_class/equipment-classes.service";


type Data = {
    title: string,
    background?: string,
    byClient?: boolean
}

type State = {
    equipmentList: EquipmentClass[]
}

class EquipmentVariety extends Component<Data, State> {
    static defaultProps = {
        byClient: false
    };

    constructor(readonly props: Data){
        super(props);
        this.state = {
            equipmentList: []
        }
    }

    componentDidMount(): void {
        equipmentClassesService.loadEquipment();
        equipmentClassesQuery
            .selectAll()
            .subscribe(items => this.setState({
                equipmentList: items
        }))
    }

    render() {
        return <div className={"equipment-variety"}>
            <H4 color={"#303030"} text={this.props.title}/>
            <ul className={"equipment-variety--list"}>
                {this.state.equipmentList.map((item: EquipmentClass) => {
                    if(this.props.byClient){
                        return <ListItem color={"#607088"} background={this.props.background} text={item.client}/>
                    }
                    return <ListItem color={"#607088"} background={this.props.background} text={item.equipmentType}/>
                })}

            </ul>
            <div className={"equipment-variety--button"}>
                <ButtonComponent background={"#FFFFFF"} color={"#72AAFC"} text={"Показать еще"} onClick={() => {}}/>
            </div>
        </div>
    }

}

export default EquipmentVariety;