import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Company } from './company.model';

export interface CompaniesState extends EntityState<Company> {}

@StoreConfig({
  name: 'companies',
  idKey: '_id',
  resettable: true
})
export class CompaniesStore extends EntityStore<CompaniesState> {

  constructor() {
    super();
  }

}

export const companiesStore = new CompaniesStore();

