import {CompaniesStore, companiesStore} from './companies.store';
import {http} from "../../index";
import {Company, CompanyFilterData, getCompanyStatus} from "./company.model";
import {paginationService} from "../pagination/pagination.service";
import Sort from "../../models/Sort";
import {convertDateToFormat} from "../../date";
import {ID} from "@datorama/akita";
import {ModalsStateType, modalsStore} from "../modals/state/modals.store";
import {Nothing} from "../../models/Nothing";
import {ResponseWrapper} from "medissima-http-client/lib";

export class CompaniesService {

  constructor(private companiesStore: CompaniesStore) {
  }

  loadItems(page: number, limit: number, sort: Sort = Sort.ASC) {
    companiesStore.setLoading(true)

    return http.get<Company[]>(`api/admin/companies?limit=${limit}&page=${page}&sort=${sort}`)
      .then(response => {
        companiesStore.reset()

        response.data.map(i => {
          companiesStore.upsert(i.id, i)
        })

        paginationService.setPaging(response.total, response.current, response.limit)
      })
      .then(() => companiesStore.setLoading(false))
  }

    loadDeletedCompanies() {
        companiesStore.setLoading(true)
        return http.get<Company[]>(`api/admin/companies/trash`)
            .then(response => {
                companiesStore.reset()
                response.data.map(i => {
                    companiesStore.upsert(i.id, i)
                })
            })
            .then(() => companiesStore.setLoading(false))
    }

    restoreCompany(id: ID):Promise<void>{
      return http.put<Company[]>(`/api/admin/companies/restore/${id}`)
          .then(response => {
              companiesStore.update(response.data)
          })
          .then(response => {
              modalsStore.update({
                  key: ModalsStateType.SUCCESS,
                  payload: {
                      text: "Компания успешно восстановлена"
                  }
              })
          })
          .catch(reason => {
              modalsStore.update({
                  key: ModalsStateType.ERROR,
                  payload: {
                      error: (reason as ResponseWrapper<Nothing>).message
                  }
              })
          })
    }


  filterItems(filter: CompanyFilterData): Promise<void> {
    companiesStore.setLoading(true)
    let url: string = `api/admin/companies/filter?`

    if(filter.status) {
      url += `status=${getCompanyStatus(filter.status)}&`
    }

    if(filter.equipmentCount) {
      url += `equipment_count=${filter.equipmentCount}&`
    }

    if(filter.date) {
      url += `registration_date=${convertDateToFormat(filter.date)}&`
    }

    return http.get<Company[]>(url)
      .then(response => {
        companiesStore.reset()

        response.data.map(i => {
          companiesStore.upsert(i.id, i)
        })

        paginationService.setPaging(0, 0, 0)
      })
      .then(() => companiesStore.setLoading(false))
  }

    searchItems(substring: string, page:number = 0, limit:number = 10): Promise<void> {
        companiesStore.setLoading(true)
        return http.post(`/api/admin/companies/search`,{substring, page, limit})
            .then(response => {
                companiesStore.reset()
                response.data.map(i => {
                    companiesStore.upsert(i.id, i)
                })
                paginationService.setPaging(response.total, response.current, response.limit)
            })
            .then(() => {
               companiesStore.setLoading(false)
            })
    }
}

export const companiesService = new CompaniesService(companiesStore);