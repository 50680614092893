import { ID } from '@datorama/akita';
import { EquipmentClassesStore, equipmentClassesStore } from './equipment-classes.store';
import {createEquipmentClass} from "./equipment-class.model";

export class EquipmentClassesService {

  constructor(private equipmentClassesStore: EquipmentClassesStore) {
  }
 loadEquipment(){
    const arr = [
      {
        name: "Чудо аппарат",
        type: "Офтальмология",
        client: "ООО “Мед Техника Клиник”"
      },
      {
        name: "Чудо аппарат 1",
        type: "Функциональная диагностика",
        client: "ООО “Мед Техника Клиник”"
      },
      {
        name: "Чудо аппарат 2",
        type: "Стерилизация и дезинфекция",
        client: "ООО “Мед Техника Клиник”"
      },
      {
        name: "Чудо аппарат 3",
        type: "МРТ",
        client: "ООО “Мед Техника Клиник”"
      }
    ];
   arr.map(item => {
     equipmentClassesStore.upsert(item.name, createEquipmentClass({
       id: item.name,
       equipmentType: item.type,
       client: item.client
     }) )
   })

 }

}

export const equipmentClassesService = new EquipmentClassesService(equipmentClassesStore);