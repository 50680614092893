import React, {Component} from "react";
import {
  Align,
  CircleCheckIcon,
  HeadComponent,
  MarkerIcon,
  PaginationFooter,
  Paragraph,
  RecycleIcon,
  TableBody,
  TableHead,
  TypeTableContent
} from "medissima-ui-kit";
import {Nothing} from "../../models/Nothing";
import {Company, CompanyFilterData} from "./company.model";
import {Status} from "../auth/auth.model";
import {paginationService} from "../pagination/pagination.service";
import {paginationQuery} from "../pagination/pagination.query";
import {companiesQuery} from "./companies.query";
import {companiesService} from "./companies.service";
import "./CompaniesPage.css"
import {ID} from "@datorama/akita";
import 'react-calendar/dist/Calendar.css';
import CompanyFilter from "../filters/CompanyFilter";
import Sort from "../../models/Sort";
import {ButtonComponent, PageHeading, SearchInput} from "medissima-ui-kit/lib";


type State = {
  companies: Company[],
  tableIsShort: boolean,
  totalPages: number,
  currentPage: number,
  limit: number,
  isLoading: boolean,
  isEmpty: boolean,
  sort: Sort
}

class CompaniesPage extends Component<any, State> {

  constructor(props: Readonly<Nothing>) {
    super(props);
    this.state = {
      companies: [],
      tableIsShort: true,
      isLoading: true,
      isEmpty: true,
      totalPages: 0,
      currentPage: 0,
      limit: 10,
      sort: Sort.ASC
    }
  }

  componentWillUnmount(): void {
    paginationService.reset()
  }

  componentDidMount(): void {
    companiesQuery.selectLoading()
      .subscribe(loading => {
        this.setState({
          ...this.state,
          isLoading: loading
        })
      })

    companiesQuery.selectAll()
      .subscribe(items => {
        this.setState({
          ...this.state,
          companies: items,
          isEmpty: items.length == 0
        })
      })

    this.loadCompanies(0)
  }

  private loadCompanies(page: number, sort?: Sort) {
    companiesService.loadItems(page, this.state.limit, sort ?? this.state.sort)
      .then(() => {
        const paging = paginationQuery.getValue();
        this.setState({
          ...this.state,
          totalPages: paging.pageCount,
          currentPage: paging.current
        })
      })
  }


  render() {
    return <div className={"view-container companies-page"}>
      <nav aria-label="breadcrumb" className="bread-crumb--component">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="#">Панель администратора</a></li>
          <li className="breadcrumb-item active" aria-current="page">Клиенты</li>
        </ol>
      </nav>
      <PageHeading title={"Клиенты"} onBack={() => this.props.history.goBack()}>
        <SearchInput onTextInput={(text: string) => {if(text.length > 0){
          this.handleOnSearch(text)
        } else {
          this.loadCompanies(0)
        }
        }}/>
        <ButtonComponent text={"Бывшие клиенты"} onClick={()=> this.props.history.push("/companies/former")} background={"#40D094"}/>
      </PageHeading>
      <CompanyFilter onSubmit={(data?: CompanyFilterData) => this.onFilterApply(data)} onSortApply={sort => this.onSortApply(sort)}/>
      {
        this.state.isEmpty ?
          <Paragraph className={"empty-text"} text={"Список клиентов пуст"} align={Align.CENTER}/> : null
      }
      {this.state.tableIsShort ? this.getShortTable() : this.getFullTable()}
      {
        this.state.totalPages > 0 ? <PaginationFooter pages={this.state.totalPages}
                                                      current={this.state.currentPage}
                                                      onClick={(page => this.handleOnPageClick(page))}/> : null
      }
    </div>
  }

  private onSortApply(sort: Sort) {
    this.setState({...this.state, sort: sort})
    this.loadCompanies(this.state.currentPage, sort)
  }

  private getFullTable() {
    return <div className={"admin-table--container"}>
    <table style={{border: "none"}} className="table table-bordered table-container admin-table companies-table--full">
      <TableHead titles={[
        "Название компании",
        "Email",
        "Количество техники",
        "Просрочено оборудования",
        "Обслуживание в этом месяце",
        "Статус"
      ]}/>
      <tbody className={"table-body"}>
      {
        this.state.companies.map(i => {
          return <tr className={this.getRowAlpha(i.status)}>
            <TableBody onClick={(id: ID) => this.handleOnItemView(i.id)} type={TypeTableContent.TEXT} text={i.name} />
            <TableBody type={TypeTableContent.TEXT} text={i.administrator_email} />
            <TableBody className={"equipment-count"} type={TypeTableContent.TEXT} text={i.equipment_count} />
            <TableBody className={"maintenance-expired"} type={TypeTableContent.TEXT} text={i.maintenance_expired} />
            <TableBody className={"maintenance-month"} type={TypeTableContent.TEXT} text={i.maintenance_this_month} />
            <TableBody type={TypeTableContent.TEXT} text={this.getCompanyReadableStatusName(i.status)} />
          </tr>
        })
      }
      </tbody>
    </table>
    </div>
  }

  private getShortTable() {
    return <div className={"admin-table--container"}>
    <table style={{border: "none"}} className="table table-bordered table-container admin-table companies-table--short">
      <TableHead titles={[
        "Название компании",
        "Email",
        "Количество техники",
        "Статус"
      ]}/>
      <tbody className={"table-body"}>
      {
        this.state.companies.map(i => {
          return <tr className={this.getRowAlpha(i.status)}>
            <TableBody onClick={(id: ID) => this.handleOnItemView(i.id)} className={"companies-table--short--info"}>
              <Paragraph className={"companies-table--short--info--name"} text={i.name} />
              <Paragraph className={"companies-table--short--info--address"}>
                <MarkerIcon/> {i.address ?? "не указан"}
              </Paragraph>
            </TableBody>
            <TableBody type={TypeTableContent.TEXT} text={i.administrator_email}/>
            <TableBody className={"companies-table--short--equipment-count"} >
              {i.equipment_count} <a onClick={e => this.handleOpeningFullTable(e)}>смотреть все</a>
            </TableBody>
            <TableBody>
              {this.getCompanyReadableStatusName(i.status)}
            </TableBody>
          </tr>
        })
      }
      </tbody>
    </table>
    </div>
  }

  private getRowAlpha(status: Status) {
    if (status == Status.IN_DISCUSS) return "has-alpha"

    return ""
  }

  private getCompanyReadableStatusName(status: Status) {
    let result;

    switch (status) {
      case Status.ON_SERVICE:
        result = <p><CircleCheckIcon/> На обслуживании</p>;
        break;
      case Status.IN_DISCUSS:
        result = <p><RecycleIcon/>В обсуждении</p>;
        break;
      case Status.PARENT:
        result = <p>Управляющая компания</p>;
        break;
      default:
        result = <p>"Неизвестен"</p>;
    }

    return result;
  }

  private handleOpeningFullTable(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()

    this.setState({
      ...this.state,
      tableIsShort: false
    })
  }

  private handleOnPageClick(page: number) {
    this.loadCompanies(page)
  }

  private handleOnItemView(id: ID) {
    this.props.history.push(`/company/${id}`);
  }

  private onFilterApply(data?: CompanyFilterData) {
    if(!data) {
      this.loadCompanies(0)
    } else {
      companiesService.filterItems(data)
        .then(() => {

        })
    }
  }

  private handleOnSearch(text: string) {
    companiesService.searchItems(text)
        .then(() => {
          const paging = paginationQuery.getValue();
          this.setState({
            ...this.state,
            totalPages: paging.pageCount,
            currentPage: paging.current
          })
        })
  }
}

export default CompaniesPage