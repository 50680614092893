import React, {Component} from "react";
import {
    Align,
    MarkerIcon,
    Paragraph,
    TableBody,
    TableHead,
    TypeTableContent
} from "medissima-ui-kit";
import {Nothing} from "../../models/Nothing";
import "./CompaniesPage.css"
import {ID} from "@datorama/akita";
import 'react-calendar/dist/Calendar.css';
import {PageHeading} from "medissima-ui-kit/lib";
import {Company} from "../companies/company.model";
import {companiesQuery} from "../companies/companies.query";
import {companiesService} from "../companies/companies.service";

type State = {
    companies: Company[],
    tableIsShort: boolean,
    isLoading: boolean,
    isEmpty: boolean
}

class FormerCompaniesPage extends Component<any, State> {

    constructor(props: Readonly<Nothing>) {
        super(props);
        this.state = {
            companies: [],
            tableIsShort: true,
            isLoading: true,
            isEmpty: true
        }
    }

    componentDidMount(): void {
        companiesService.loadDeletedCompanies()

        companiesQuery.selectLoading()
            .subscribe(loading => {
                this.setState({
                    ...this.state,
                    isLoading: loading
                })
            })

        companiesQuery.selectAll()
            .subscribe(items => {
                this.setState({
                    ...this.state,
                    companies: items,
                    isEmpty: items.length == 0
                })
            })
    }


    render() {
        return <div className={"view-container"}>
            <nav aria-label="breadcrumb" className="bread-crumb--component">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Панель администратора</a></li>
                    <li className="breadcrumb-item"><a href="#/companies">Клиенты</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Бывшие клиенты</li>
                </ol>
            </nav>
            <PageHeading title={"Бывшие клиенты"} onBack={() => this.props.history.goBack()} />
            {
                this.state.isEmpty ?
                    <Paragraph className={"empty-text"} text={"Список оборудования пуст"} align={Align.CENTER}/> : null
            }
            {this.getShortTable()}
        </div>
    }

    private getShortTable() {
        return <div className={"admin-table--container"}>
            <table style={{border: "none"}} className="table table-bordered table-container admin-table companies-table--short">
                <TableHead titles={[
                    "Название компании",
                    "Email",
                    "Количество техники",
                    "Восстановить"
                ]}/>
                <tbody className={"table-body"}>
                {
                    this.state.companies.map(i => {
                        return <tr>
                            <TableBody className={"companies-table--short--info"}>
                                <Paragraph className={"companies-table--short--info--name"} text={i.name} />
                                <Paragraph className={"companies-table--short--info--address"}>
                                    <MarkerIcon/> {i.address ?? "не указан"}
                                </Paragraph>
                            </TableBody>
                            <TableBody type={TypeTableContent.TEXT} text={i.administrator_email}/>
                            <TableBody className={"companies-table--short--equipment-count"} >
                                <span>{i.equipment_count}</span>
                            </TableBody>
                            <TableBody id={i.id} type={TypeTableContent.DELETE}
                                       className={"former-companies-reestablish delete-type--table"}
                                       btnText={"Восстановить"}
                                       onClick={(id: number | string) => this.restoreCompany(i.id)}
                                       btnBackground={"#40D094"}
                            />
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    }

    private restoreCompany(id: ID){
        companiesService.restoreCompany(id)
            .finally(() => {
                companiesService.loadDeletedCompanies()
                companiesQuery.selectAll()
                    .subscribe(items => {
                        this.setState({
                            ...this.state,
                            companies: items,
                            isEmpty: items.length == 0
                        })
                    })
            })

    }

}

export default FormerCompaniesPage