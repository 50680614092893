import {ID, resetStores} from '@datorama/akita';
import { AuthStore, authStore } from './auth.store';
import {http} from "../../index";
import {Auth} from "./auth.model";
import {ResponseWrapper} from "medissima-http-client/lib";
import {Nothing} from "../../models/Nothing";

export class AuthService {

  constructor(private authStore: AuthStore) {
  }

  attemptLogin(email: string, password: string): Promise<boolean> {
    authStore.setLoading(true)

    return http.post<Auth>("api/admin/sign-in", {
        email: email,
        password: password
      })
      .then((response: ResponseWrapper<Auth>) => {
        authStore.setLoading(false)
        authStore.upsert(response.data.id, response.data)
        return response.code < 400
      })
      .catch(reason => {
        const result = reason.response.data as any as ResponseWrapper<Nothing>;
        console.error(result)

        authStore.setError(result.message)
        return false
      })
  }

  logout() {
    resetStores()
  }

}

export const authService = new AuthService(authStore);