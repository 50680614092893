import React, {Component} from "react";
import {CompanyFilterData, getCompanyStatus, mapCompanyStatuses, mapStatusToTitle} from "../companies/company.model";
import {ButtonComponent, CalendarIcon, DropDownComponent, FilterListItem, Paragraph, TextSize} from "medissima-ui-kit";
import Calendar from 'react-calendar';
import "./Filter.css"
import Sort from "../../models/Sort";
import {KeyValue} from "../../models/KeyValue";
import {convertDateToFormat} from "../../date";

type Data = {
  onSubmit: (data?: CompanyFilterData) => void,
  onSortApply: (sort: Sort) => void
}

type State = {
  isPickerActive?: boolean,
  date?: Date,
  status?: string,
  equipmentCount?: number,
  filterSelectedData: KeyValue[],
  resetStatus?: boolean,
  resetEquipment?: boolean,
  resetSort?: boolean
}

class CompanyFilter extends Component<Data, State> {

  private equipmentCountValues = [10, 25, 50, 100];

  constructor(props: Readonly<any>) {
    super(props);

    this.state = {
      filterSelectedData: []
    }
  }

  render() {
    return <div className={"filter-area"}>
      <div className={"filter-area--selection"}>
        <div className={"calendar"}>
          <CalendarIcon
            fill={"#40D094"}
            onClick={() => this.setState({
              ...this.state,
              isPickerActive: !this.state.isPickerActive
            })}
          />
          <Calendar
            className={this.state.isPickerActive ? "": "hidden"}
            onChange={date => this.onDatePicked(date)}
            value={this.state.date ?? new Date()}
          />
          <Paragraph text={convertDateToFormat(this.state.date) ?? "Дата регистрации"} color={"#607088"} size={TextSize.P_18}/>
        </div>
        <div className={"filter-area--selection--other"}>
          <DropDownComponent
            id={"company--filter-area--status"}
            placeholder={"Статус"}
            items={mapCompanyStatuses()}
            searchable={false}
            onItemSelected={(_: number, value: string) => this.onStatusSelected(value)}
            reset={this.state.resetStatus}
          />
          <DropDownComponent
            id={"company--filter-area--equipment-count"}
            placeholder={"Кол-во техники"}
            items={this.equipmentCountValues.map(i => `> ${i}`)}
            searchable={false}
            onItemSelected={(id: number, _: string) => this.onEquipmentCountSelected(this.equipmentCountValues[id])}
            reset={this.state.resetEquipment}
          />
          <div className={"company--filter-area--action-buttons"}>
            <ButtonComponent text={"Применить"} background={"#40D094"} onClick={() => this.onFilterSubmit()} />
            <ButtonComponent text={"Сбросить"} background={"#FC5858"} onClick={() => this.onResetFilter()} />
          </div>
        </div>
      </div>
      <div className={"filter-area--bottom"}>
        <div className={"filter-area--selected-items"}>
          {
            this.state.filterSelectedData
              .map((i, index) => <FilterListItem
                id={index}
                text={`${i.key}: ${i.value}`}
                onRemove={i => this.onDeleteItemFromFilter(i)}
              />)
          }
        </div>
        <div className={"filter-area--sort"}>
          <DropDownComponent
            id={"company--filter-area--sort"}
            placeholder={"Сортировка"}
            items={["по убыванию", "по возрастанию"]}
            searchable={false}
            reset={this.state.resetSort}
            onItemSelected={(id: number, _: string) => {
              this.setState({
                resetSort: false
              })
              console.log(id)

              let sort: Sort;

              if(id == 0) {
                sort = Sort.ASC
              } else {
                sort = Sort.DESC
              }

              this.props.onSortApply(sort)
            }}
          />
        </div>
      </div>
    </div>
  }

  private onDatePicked(date: Date) {
    console.log(date)

    this.setState({
      ...this.state,
      date: date,
      isPickerActive: false
    })
  }

  private onStatusSelected(status: string) {
    this.setState({
      ...this.state,
      status: status,
      resetStatus: false
    })
  }

  private onEquipmentCountSelected(count: number) {
    this.setState({
      ...this.state,
      equipmentCount: count,
      resetEquipment: false
    })
  }

  private onFilterSubmit() {
    const data :KeyValue[] = []

    if(this.state.status) {
      data.push({
        key: "Статус",
        value: this.state.status
      })
    }

    if(this.state.date) {
      data.push({
        key: "Дата регистрации",
        value: convertDateToFormat(this.state.date)
      })
    }

    if(this.state.equipmentCount) {
      data.push({
        key: "Кол-во оборудования",
        value: `> ${this.state.equipmentCount}`
      })
    }

    this.setState({
      ...this.state,
      filterSelectedData: data
    })

    this.props.onSubmit(
      {
        date: this.state.date,
        status: this.state.status,
        equipmentCount: this.state.equipmentCount
      }
    )
  }

  private onResetFilter() {
    this.props.onSubmit(undefined)

    this.setState({
      ...this.state,
      filterSelectedData: [],
      status: undefined,
      date: undefined,
      equipmentCount: undefined,
      resetEquipment: true,
      resetStatus: true,
      resetSort: true
    })
  }

  private onDeleteItemFromFilter(index: number) {
    const filterSelectedData = this.state.filterSelectedData;
    delete filterSelectedData[index];

    this.setState({
      ...this.state,
      filterSelectedData: filterSelectedData
    });

    let filterData: CompanyFilterData = {

    }

    filterSelectedData.forEach(i => {
      console.log("filterSelectedData -> ", i)

      switch (i.key) {
        case "Статус":
          filterData = {
            ...filterData,
            status: this.state.status
          }
          break;
        case "Дата регистрации":
          filterData = {
            ...filterData,
            date: this.state.date
          }
          break;
        case "Кол-во оборудования":
          filterData = {
            ...filterData,
            equipmentCount: this.state.equipmentCount
          }
          break;
      }
    })

    console.log(filterData)

    this.props.onSubmit(filterData)
  }
}

export default CompanyFilter;