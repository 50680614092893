import React, {Component} from "react";
import {ButtonComponent, ListItem, H4} from "medissima-ui-kit";
import "./Variable.css"

type Data = {
    title: string,
    background?: string,
    selectedBackground?: string,
    items: Array<string>,
    onClick?: (title: string) => void,
    btnText?: string,
    needButton: boolean
}

type State = {
    selectedIndex?: number
}

class VariableList extends Component<Data, State> {

    static defaultProps = {
        btnText: "Показать еще",
        needButton: false
    }

    render() {
        return <div className={"variable"}>
            <H4 color={"#303030"} text={this.props.title}/>
            <ul className={"variable--list"}>
                {this.props.items.map((it, index) => {
                  return <ListItem
                    onClick={() => {
                        if(this.props.onClick){
                            this.props.onClick(it)
                            this.setState({
                              ...this.state,
                              selectedIndex: index
                            })
                        }
                    }}
                    color={"#607088"}
                    background={index == this.state?.selectedIndex ? this.props.selectedBackground : this.props.background }
                    text={it}
                  />
                })}
            </ul>
            {this.props.needButton ? <div className={"variable--button"}>
                <ButtonComponent background={"#FFFFFF"} color={"#72AAFC"} text={this.props.btnText} onClick={this.props.onClick}/>
            </div> : null}

        </div>
    }

}

export default VariableList;