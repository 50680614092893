import { QueryEntity } from '@datorama/akita';
import { ClientsStore, ClientsState, clientsStore } from './clients.store';

export class ClientsQuery extends QueryEntity<ClientsState> {

  constructor(protected store: ClientsStore) {
    super(store);
  }

}

export const clientsQuery = new ClientsQuery(clientsStore);
