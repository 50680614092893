import React, {Component} from "react";
import {Link, Redirect} from "react-router-dom";
import {Nothing} from "../../models/Nothing";
import {AuthLogoAdmin, H3, Loader, LoginForm} from "medissima-ui-kit";
import "../../css/Registration.css"
import {authService} from "./auth.service";
import {authQuery} from "./auth.query";
import {authStore} from "./auth.store";
import {Subscription} from "rxjs";

type State = {
    needRedirect: boolean,
    isLoading: boolean,
    error?: string
}

class SignInAdmin extends Component<Nothing, State> {

    private readonly subscriptionBag: Array<Subscription> = [];

    constructor(props: Readonly<Nothing>) {
        super(props);

        this.state = {
            needRedirect: false,
            isLoading: false,
            error: undefined
        }
    }

    componentDidMount(): void {
      authStore.setError(undefined)

      this.subscriptionBag.push(
        authQuery.selectLoading()
          .subscribe(loading => {
            this.setState({
              ...this.state,
              isLoading: loading
            })
          })
      )

      this.subscriptionBag.push(
        authQuery.selectError()
          .subscribe(error => {
            this.setState({
              ...this.state,
              error: error
            })
          })
      )
    }

    componentWillUnmount(): void {
      this.subscriptionBag.forEach(i => i.unsubscribe())
    }

  render() {
        if (this.state.needRedirect) {
            return <Redirect to={"dashboard-admin"}/>
        }

        return <div
            className={'authorization-form--container'}
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + "auth_back.jpg"})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%"
            }}>
            <div className={"auth--logo"}>
                <AuthLogoAdmin />
                <H3 text={"admin"} color={"#FFFFFF"}/>
            </div>

            <LoginForm
              error={this.state.error}
              onFormSubmit={(email: string, password: string) => this.handleOnFormSubmit(email, password)}
              formTitle={"Панель администратора"} />

            { this.state.isLoading ? <Loader /> : null }

        </div>;
    }

    private handleOnFormSubmit(email: string, password: string) {
        authService.attemptLogin(email, password)
          .then(isSuccess => {
              this.setState({
                  needRedirect: isSuccess
              })
          })


    }
}

export default SignInAdmin