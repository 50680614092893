import { QueryEntity } from '@datorama/akita';
import { CompaniesStore, CompaniesState, companiesStore } from './companies.store';

export class CompaniesQuery extends QueryEntity<CompaniesState> {

  constructor(protected store: CompaniesStore) {
    super(store);
  }

}

export const companiesQuery = new CompaniesQuery(companiesStore);
