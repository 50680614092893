import { QueryEntity } from '@datorama/akita';
import { AuthStore, AuthState, authStore } from './auth.store';
import {Auth} from "./auth.model";

export class AuthQuery extends QueryEntity<AuthState> {

  constructor(protected store: AuthStore) {
    super(store);
  }

  findAuthSession(): Auth | undefined {
    return this.getAll().values().next().value
  }

  listenAuthState(callback: (isAuthed: boolean) => void) {
    console.log("///--------listenAuthState----------///");

    this.selectFirst()
      .subscribe(authState => {
        console.log(authState);
        callback(authState !== undefined)
      })
  }

}

export const authQuery = new AuthQuery(authStore);
