import React, {Component} from "react";
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import {Nothing} from "../models/Nothing";
import SignInAdmin from "./auth/SignInAdmin";
import Sidebar from "./sidebar/Sidebar";
import DashboardAdmin from "./dashboard/DashboardAdmin";
import CategoriesCatalog from "./equipment/CategoriesCatalog";
import EquipmentsPage from "./equipment/EquipmentsPage";
import {authQuery} from "./auth/auth.query";
import AccessControlPage from "./access/AccessControlPage";
import ProfilePage from "./profile/ProfilePage";
import EquipmentItemsPage from "./equipment-items/EquipmentItemsPage";
import CompaniesPage from "./companies/CompaniesPage";
import FormerCompaniesPage from "./companies/FormerCompaniesPage";

type State = {
    isAuthenticated?: boolean
}

class Body extends Component<Nothing, State> {

    constructor(readonly props: Nothing) {
        super(props);

        this.state = {
            isAuthenticated: false
        }
    }

    componentDidMount(): void {
        authQuery.listenAuthState(isAuthed => {
            console.log("is authed -> " + isAuthed)
            this.setState({
              isAuthenticated: isAuthed
            })
        })
    }

    render() {
        return <div className={"row body-row"}>
            <Router>
                { this.state?.isAuthenticated ? <Sidebar/> : null }
                <div className={`body-col col`}>
                    <Switch>
                        <Route exact path="/" component={this.state.isAuthenticated ? DashboardAdmin: SignInAdmin}/>
                        <Route path="/sign-in" component={this.state.isAuthenticated ? DashboardAdmin: SignInAdmin}/>
                        <Route path="/catalog" component={CategoriesCatalog}/>
                        <Route path="/category/:id" component={EquipmentsPage}/>
                        <Route path="/access" component={AccessControlPage}/>
                        <Route path="/equipment" component={EquipmentItemsPage}/>
                        <Route exact path="/companies" component={CompaniesPage}/>
                        <Route path="/companies/former" component={FormerCompaniesPage}/>
                        <Route path="/company/:id" component={ProfilePage}/>
                    </Switch>
                </div>
            </Router>
        </div>
    }

}

export default Body