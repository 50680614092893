import React, {Component} from "react";
import "../css/Modals.css"
import {ButtonComponent, H1, StyledFileInput, TextInput, PhotoArea} from "medissima-ui-kit";
import {fileService, UploadData} from "../../common/file.service";
import {ID} from "@datorama/akita";
import {categoryService} from "../../equipment/services/category.service";
import {Category} from "../../equipment/models/category.model";
import {categoryQuery} from "../../equipment/queries/category.query";

type Data = {
    id: ID
}

type State = {
    uploadedFile?: UploadData,
    categoryName: string,
    category?: Category,
    urlName: string,
    fileName: string
}

class EditCategoryModal extends Component<Data, State> {
    constructor(props: Readonly<Data>) {
        super(props);
        this.state = {
            categoryName: "",
            urlName: "",
            fileName: ""
        }
    }
    componentDidMount(): void {
        const categoryID = this.props.id;
        categoryQuery.selectEntity(categoryID)
            .subscribe(i => {
                this.setState({
                    ...this.state,
                    category: i,
                    categoryName: i.name,
                    urlName: i.thumb.url ?? "",
                    fileName: ""
                })
            })
    }

    render() {
        return <div className={"base-modal modal--edit-category"}>
            <H1 text={this.state.category?.name} />
            <TextInput id={"edit-category"} label={"Изменить название категории"} placeholder={"Введите название категории"}  onInputChange={value => {this.setState({...this.state, categoryName: value})}}/>
            <PhotoArea photo={this.state.urlName}/>
            <StyledFileInput text={this.state.fileName !== "" ? this.state.fileName : "Загрузить фотографию"} id={"edit-category-file"} onFileChanged={(file?: File|null) => this.onFileUploaded(file)} />
            <ButtonComponent background={"#40D094"} text={"Сохранить"} onClick={() => this.onFormSubmit()}/>
        </div>
    }

    private onFileUploaded(file?: File|null) {
        if(file !== undefined && file !== null) {
            fileService.uploadFile(file)
                .then(data => {
                    this.setState({
                        uploadedFile: data
                    })
                })
            this.setState({
                ...this.state,
                urlName: URL.createObjectURL(file),
                fileName: file.name
            })
        }
    }

    private onFormSubmit() {
            categoryService.updateCategory(this.props.id, {
                name: this.state.categoryName,
                thumbnail: {
                    url: this.state.uploadedFile?.file_url ?? this.state.urlName,
                    file_name: this.state.uploadedFile?.file_name ?? this.state.fileName

                }
            })
    }
}

export default EditCategoryModal;