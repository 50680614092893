import React, {Component} from "react";
import SidebarMenu from "./SidebarMenu";
import {AuthLogoAdmin, H3} from "medissima-ui-kit";
import AdminItem from "./menu_items/AdminItem";

type Data = {
    className?: string
}

class Sidebar extends Component<Data> {

    render() {
        return <aside className={`${this.props.className ?? ""} sidebar--container col col-sm-3`} >
            <div className={"sidebar-logo"}>
                <a href={"/#/"}>
                    <AuthLogoAdmin width={"64px"} height={"64px"}/>
                    <H3 text={"admin"} color={"#FFFFFF"}/>
                </a>
            </div>
            <SidebarMenu/>
            <AdminItem className={"sidebar--admin-profile"}/>
        </aside>
    }
}

export default Sidebar;