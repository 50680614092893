import React, {Component} from "react";
import "../css/Modals.css"
import {
  ButtonComponent,
  DateInput,
  DropDownComponent,
  H2,
  ReviewsForm,
  StyledFileInput,
  TextInput
} from "medissima-ui-kit";
import {
  createPeriodicityMap,
  CustomReview,
  Periodicity,
  reversePeriodicityMap
} from "../../equipment/models/equipment.model";
import {categoryQuery} from "../../equipment/queries/category.query";
import {Category} from "../../equipment/models/category.model";
import {equipmentService} from "../../equipment/services/equipment.service";
import {fileService, UploadData} from "../../common/file.service";

enum Step {
  BASE, REVIEWS
}

type Data = {
  onSuccess?: () => void
}

type State = {
  currentStep: Step,
  categories: Category[],
  name?: string,
  image?: string,
  categoryId?: number,
  date?: string,
  customs: CustomReview[],
  verificationPeriodicity?: Periodicity,
  documentation?: string,
  errorName?: string,
  errorCategory?: string,
  errorVerification?: string,
  upload?: UploadData,
  reset?: boolean
}

class EquipmentModal extends Component<Data, State> {

  private readonly periodicityMap = createPeriodicityMap()
  private reviews: {name: string, period: string}[] = []

  constructor(props: Readonly<Data>) {
    super(props);

    this.state = {
      categories: [],
      currentStep: Step.BASE,
      customs: [],
      categoryId: 0
    }
  }

  componentDidMount(): void {
    categoryQuery.selectAll()
      .subscribe(cats => {
        this.setState({
          ...this.state,
          categories: cats
        })
      })
  }

  render() {
    return <div className={"base-modal modal--equipment"}>
      {
        this.renderModalContent()
      }
    </div>
  }

  renderCreateBaseStep() {
    return <div className={"modal--equipment--base"}>
      <H2 text={"Добавить\n" + "оборудование"} />
      <TextInput
        id={"name"}
        className={"modal--equipment--name"}
        onInputChange={value => this.handleOnNameInput(value)}
        placeholder={"Название оборудования"}
        error={this.state.errorName}
      />
      <DropDownComponent
        id={"category"}
        items={this.state.categories.map(i => i.name)}
        placeholder={"Выберите категорию"}
        onItemSelected={(index: number, name: string) => this.onCategorySelect(name)}
        error={this.state.errorCategory}
      />
      <StyledFileInput
        id={"photo"}
        text={this.state.image}
        onFileChanged={file => this.handleOnThumbUploaded(file)}
      />
      {/*TODO: вызывает ошибку открытия модольного окна*/}
      {/*<DateInput*/}
      {/*  className={"modal--equipment--date"}*/}
      {/*  id={"date"}*/}
      {/*  placeholder={"Дата выпуска"}*/}
      {/*  onInputChange={value => this.handleOnDateInput(value)}*/}
      {/*/>*/}
      <ButtonComponent
        background={"#40D094"}
        text={"Далее"}
        onClick={() => this.handleOnNextClick()}
      />
    </div>
  }

  private renderAddingReviewsStep() {
    return <div className={"modal--equipment--reviews"}>
      <H2 text={this.state.name} />
      <ReviewsForm
        title={"Периодичность ТО"}
        onFormChange={(names: string[], periods: string[]) => this.handleOnReviewChange(names, periods)}
      />
      <DropDownComponent
        className={"modal--equipment--periodicity"}
        id={"periodicity"}
        placeholder={"Периодичность поверок"}
        items={this.periodicityMap.map(i => i.title)}
        reset={this.state.reset}
        error={this.state.errorVerification}

        onItemSelected={(id, value) => {
          console.log(this.state.verificationPeriodicity)
          this.setState({
            ...this.state,
            verificationPeriodicity: reversePeriodicityMap(value),
            reset: false
          })
        }}
      />
      <StyledFileInput
        text={this.state.documentation ?? "Загрузить документацию к оборудованию"}
        id={"document"}
        onFileChanged={file => this.handleOnDocUploaded(file)}
      />
      <ButtonComponent background={"#40D094"} text={"Сохранить"} onClick={() => this.handleOnFormSubmit()} />
    </div>
  }

  private handleOnNameInput(value: string) {
    this.setState({
      ...this.state,
      name: value,
      errorName: undefined
    })
  }

  private handleOnThumbUploaded(file: File | null | undefined) {
    this.setState({
      ...this.state,
      image: file?.name
    })

    if(file !== null && file !== undefined) {
      fileService.uploadFile(file)
          .then(data => {
            this.setState({
              upload: data
            })
          })
        .catch(_ => {
          this.setState({
            ...this.state,
            image: "Ошибка загрузки файла"
          })
        })
    }
  }

  private handleOnDocUploaded(file: File | null | undefined) {
    console.log(file)

    this.setState({
      ...this.state,
      documentation: file?.name
    })

    if(file !== null && file !== undefined) {
      fileService.uploadFile(file)
        .catch(_ => {
          this.setState({
            ...this.state,
            documentation: "Ошибка загрузки файла"
          })
        })
    }
  }

  private handleOnDateInput(value: string) {
    this.setState({
      ...this.state,
      date: value
    })
  }

  private renderModalContent() {
    switch (this.state.currentStep) {
      case Step.BASE:
        return this.renderCreateBaseStep()
      case Step.REVIEWS:
        return this.renderAddingReviewsStep()
    }
  }

  private onCategorySelect(name: string) {
    const categoryId = Number.parseInt(this.state.categories.find(i => i.name == name)?.id.toString() ?? "0");

    this.setState({
      ...this.state,
       categoryId: categoryId,
       errorCategory: undefined,
       reset: false
    })
  }

  private handleOnNextClick() {
    if(this.state.name === undefined || this.state.name === ""){
      this.setState({
          ...this.state,
        errorName: "Введите наименование оборудования"
      })
    } else if(this.state.categoryId === 0){
      this.setState({
        ...this.state,
        errorCategory: "Выберите категорию оборудования"
      })
    }
    else {
      this.setState({
        ...this.state,
        currentStep: Step.REVIEWS
      })
    }
    this.setState({
      reset: true
    })
  }

  private handleOnReviewChange(names: string[], periods: string[]) {
    names.forEach((value, index) => {
      this.reviews[index] = {
        name: value,
        period: periods[index]
      }
    })

    this.setState({
      ...this.state,
      customs: this.reviews.map(i => {
        return {
          name: i.name,
          periodicity: reversePeriodicityMap(i.period)
        }
      })
    })
  }

  private handleOnFormSubmit() {
    const state = this.state;

    if (this.state.verificationPeriodicity !== undefined) {
      equipmentService.createEquipment(
          {
            name: state.name,
            thumb: {
              file_name: state.upload?.file_name ?? "",
              url: state.upload?.file_url ?? ""
            },
            documentation: state.documentation,
            periodicity: state.verificationPeriodicity,
            category_id: state.categoryId,
            customs: state.customs
          }
      ).then(() => {
        if (this.props.onSuccess)
          this.props.onSuccess()
      })
    } else {
      this.setState({
        errorVerification: "Выберите переодичность поверок"
      })
    }
  }

}

export default EquipmentModal;