import {Store, StoreConfig} from '@datorama/akita';
import {Status} from "../../auth/auth.model";

export interface ProfileState {
  id: number;
  organization_data: Organization
  reviews: ReviewData[],
  users: UserData[],
  equipments: EquipmentData[]
}

export type Organization = {
  address: string,
  name: string,
  status: Status
}

export type EquipmentData = {
  id: number,
  address: string,
  category: string,
  name: string
}

export type ReviewData = {
  date: Date,
  id: number,
  name: string
}

export type UserData = {
  id: number,
  is_admin: boolean,
  email: string,
  name: string,
}

export function createInitialState(): ProfileState {
  return {
    id: 0,
    organization_data: {
      address: "",
      name: "",
      status: Status.UNKNOWN
    },
    reviews: [],
    users: [],
    equipments: []
  };
}

@StoreConfig({ name: 'profile' })
export class ProfileStore extends Store<ProfileState> {

  constructor() {
    super(createInitialState());
  }

}

export const profileStore = new ProfileStore();

