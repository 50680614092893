import React, {Component} from "react";
import {
  Align,
  Center,
  EquipmentListItem,
  HeadComponent,
  Loader,
  PaginationFooter,
  Paragraph
} from "medissima-ui-kit";
import {EquipmentFilterData, EquipmentItem} from "./equipment-item.model";
import {paginationService} from "../pagination/pagination.service";
import {paginationQuery} from "../pagination/pagination.query";
import {equipmentItemQuery} from "./equipment-item.query";
import {equipmentItemService} from "./equipment-item.service";
import Sort from "../../models/Sort";
import "./EquipmentItemsPage.css"
import EquipmentFilter from "../filters/EquipmentFilter";
import {ButtonComponent, PageHeading, SearchInput} from "medissima-ui-kit/lib";

type State = {
  totalPages: number,
  currentPage: number,
  limit: number,
  isLoading: boolean,
  isEmpty: boolean,
  items: EquipmentItem[],
  sorting: Sort
}

class EquipmentItemsPage extends Component<any, State> {

  constructor(props: Readonly<any>) {
    super(props);

    this.state = {
      isLoading: true,
      isEmpty: true,
      totalPages: 0,
      currentPage: 0,
      limit: 10,
      items: [],
      sorting: Sort.ASC
    }
  }

  componentWillUnmount(): void {
    paginationService.reset()
  }

  componentDidMount(): void {
    equipmentItemQuery.selectLoading()
      .subscribe(loading => {
        this.setState({
          ...this.state,
          isLoading: loading
        })
      })

    equipmentItemQuery.selectAll()
      .subscribe(items => {
        this.setState({
          ...this.state,
          items: items,
          isEmpty: items.length == 0
        })
      })

    this.loadEquipment(0)
  }

  private loadEquipment(page: number, sort?: Sort) {
    equipmentItemService.loadItems(page, this.state.limit, sort ?? this.state.sorting)
      .then(() => {
        const paging = paginationQuery.getValue();
        this.setState({
          ...this.state,
          totalPages: paging.pageCount,
          currentPage: paging.current
        })
      })
  }

  render() {
    return <div className={"view-container"}>
      <nav aria-label="breadcrumb" className="bread-crumb--component">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="#">Панель администратора</a></li>
          <li className="breadcrumb-item active" aria-current="page">Оборудование</li>
        </ol>
      </nav>
      <PageHeading title={"Оборудование"} onBack={() => {this.props.history.goBack()}}>
        <SearchInput onTextInput={(text: string) => {if(text.length > 0){
          this.handleOnSearch(text)
        } else {
          this.loadEquipment(0)
        }
        }}/>

      </PageHeading>
      <EquipmentFilter onSortApply={sort => this.onSortApply(sort)}  onSubmit={data => this.onFilterSubmit(data)}/>
      <Center>
        <Loader visible={this.state.isLoading}/>
      </Center>
      {
        this.state.isEmpty ?
          <Paragraph className={"empty-text"} text={"Список оборудования пуст"} align={Align.CENTER}/> : null
      }
      <div className={"equipment-items"}>
        {
          this.state.items.map(i => {
            return <EquipmentListItem
              id={i.id}
              name={i.name}
              category={i.category}
              address={i.address}
            />
          })
        }
      </div>
      {
        this.state.totalPages > 0 ? <PaginationFooter pages={this.state.totalPages}
                                                      current={this.state.currentPage}
                                                      onClick={(page => this.handleOnPageClick(page))} /> : null
      }
    </div>
  }

  private handleOnPageClick(page: any) {
    this.loadEquipment(page)
  }

  private handleOnSearch(text: string) {
    equipmentItemService.searchItems(text)
      .then(() => {
        const paging = paginationQuery.getValue();
        this.setState({
          ...this.state,
          totalPages: paging.pageCount,
          currentPage: paging.current
        })
      })
  }

  private onFilterSubmit(data?: EquipmentFilterData) {
    if(!data) {
      this.loadEquipment(this.state.currentPage)
    } else {
      equipmentItemService.filterItems(data)
        .then(() => {
          this.setState({
            totalPages: 0
          })
        })
    }
  }

  private onSortApply(sort: Sort) {
    this.setState({
      ...this.state,
      sorting: sort
    })

    this.loadEquipment(this.state.currentPage, sort)
  }
}

export default EquipmentItemsPage;