import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Client } from './client.model';

export interface ClientsState extends EntityState<Client> {}

@StoreConfig({
  name: 'Clients',
  idKey: '_id',
  resettable: true
})
export class ClientsStore extends EntityStore<ClientsState> {

  constructor() {
    super();
  }

}

export const clientsStore = new ClientsStore();

