import React, {Component} from "react";
import {ButtonComponent, H2, EquipmentListItem, Center, BaseCard, Weight} from "medissima-ui-kit";
import VariableList from "../../components/VariableList";
import {EquipmentData} from "../akita/profile.store";
import {profileQuery} from "../akita/profile.query";

type Data = {
    redirectToEquipment: () => void
}

type State = {
    items: EquipmentData[],
    count: number,
}

class Equipments extends Component<Data, State> {

    constructor(props: Readonly<Data>) {
        super(props);

        this.state = {
            items: [],
            count: 5
        }
    }

    componentDidMount(): void {
        profileQuery.select()
          .subscribe(profile => {
              this.setState({
                ...this.state,
                  items: profile.equipments
              })
          })
    }

    render() {
        return <BaseCard className={"admin-panel-card client "}>
            <div className={"admin-panel-card--title"}>
                <H2 color={"#303030"} text={"Оборудование"}/>
                <ButtonComponent fontWeight={Weight.BOLD} background={"#72AAFC"} text={"Смотреть все"} onClick={() => this.props.redirectToEquipment()}/>
            </div>
            <div className={"admin-panel-card--body"}>
                <VariableList
                  onClick={(title: string) => this.handleOnFilterClicked(title)}
                  title={"По типу"}
                  items={profileQuery.getValue().equipments.map(i => i.category).filter((thing, i, arr) => {
                      return arr.indexOf(arr.find(t => t === thing) ?? "") === i;
                  })}
                  selectedBackground={"#a6dcff"}
                  background={"#EAF2FC"}
                  needButton={false}
                />
            </div>
            <hr/>
            {
                this.state.items
                  .slice(0, this.state.count)
                  .map(i => {
                    return <EquipmentListItem
                      id={i.id}
                      name={i.name}
                      category={i.category}
                      address={i.address}
                    />
                })
            }
            <Center>
                <ButtonComponent className={this.state.count > (this.state.items?.length ?? 0) ? "hidden" : ""} background={"#FFFFFF"}
                                 color={"#72AAFC"}
                                 text={"Показать еще"}
                                 border={"#72AAFC"}
                                 onClick={() => this.handleOnMoreClicked()}
                />
            </Center>
        </BaseCard>
    }

    private handleOnMoreClicked() {
        this.setState({
          ...this.state,
            count: this.state.count + 5
        })
    }

    private handleOnFilterClicked(category: string) {
        this.setState({
          ...this.state,
            items: profileQuery.getValue().equipments.filter(i => i.category == category)
        })
    }
}

export default Equipments;