import { ID } from '@datorama/akita';
import {FilterItem, FilterStore, filterStore, PeriodicityReadable} from './filter.store';
import {http} from "../../../index";
import {filterQuery} from "./filter.query";

export class FilterService {

  constructor(private filterStore: FilterStore) {}

  initFilterData() {
    http.get<FilterItem>("api/admin/filter/categories")
      .then(response => {
        const value = filterQuery.getValue();

        filterStore.update({
          ...value,
          categories: response.data
        })
      })

    http.get<FilterItem>("api/admin/filter/companies")
      .then(response => {
        const value = filterQuery.getValue();

        filterStore.update({
          ...value,
          companies: response.data
        })
      })

    http.get<FilterItem>("api/admin/filter/equipment")
      .then(response => {
        const value = filterQuery.getValue();

        filterStore.update({
          ...value,
          equipments: response.data
        })
      })
  }

}

export const filterService = new FilterService(filterStore);