import { ID } from '@datorama/akita';
import { EquipmentStore, equipmentStore } from '../stores/equipment.store';
import {http} from "../../../index";
import {CreateEquipment, Equipment} from "../models/equipment.model";
import {paginationService} from "../../pagination/pagination.service";
import {ModalsStateType, modalsStore} from "../../modals/state/modals.store";

export class EquipmentService {

  constructor(private equipmentStore: EquipmentStore) {
  }

  loadEquipmentByCategory(category: number, page: number = 0, limit: number = 10): Promise<void> {
    equipmentStore.setLoading(true)

    return http.get<Equipment[]>(`api/admin/equipment/${category}?page=${page}&limit=${limit}`)
      .then(response => {
        equipmentStore.reset()
        response.data.map(i => {
          equipmentStore.upsert(i.id, i)
        })

        paginationService.setPaging(response.total, response.current, response.limit)
      })
      .then(() => {
        equipmentStore.setLoading(false)
      })
  }

  createEquipment(data: CreateEquipment): Promise<void> {
    equipmentStore.setLoading(true)
    modalsStore.update({
      key: ModalsStateType.UNKNOWN
    })

    return http.post("api/admin/equipment", data)
      .then(response => {
        equipmentStore.upsert(response.data.id, response.data)
      })
      .finally(() => {
        equipmentStore.setLoading(false)
        modalsStore.update({
          key: ModalsStateType.SUCCESS,
            payload:{
              text: "Оборудование успешно добавлено"
            }
        })
      })
  }

  deleteItem(id: ID) {
    http.rdelete(`api/admin/equipment/${id}`)
      .then(response => {
        if(response.data) {
          equipmentStore.remove(id)
        }
      })
  }
}

export const equipmentService = new EquipmentService(equipmentStore);