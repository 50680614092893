import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { EquipmentItem } from './equipment-item.model';

export interface EquipmentItemState extends EntityState<EquipmentItem> {}

@StoreConfig({
  name: 'equipment-item',
  idKey: '_id',
  resettable: true
})
export class EquipmentItemStore extends EntityStore<EquipmentItemState> {

  constructor() {
    super();
  }

}

export const equipmentItemStore = new EquipmentItemStore();

