import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { EquipmentClass } from './equipment-class.model';

export interface EquipmentClassesState extends EntityState<EquipmentClass> {}

@StoreConfig({
  name: 'EquipmentClasses',
  idKey: '_id'
})
export class EquipmentClassesStore extends EntityStore<EquipmentClassesState> {

  constructor() {
    super();
  }

}

export const equipmentClassesStore = new EquipmentClassesStore();

