import { ID } from '@datorama/akita';

export interface Equipment {
  id: ID;
  name: string;
  category?: string;
}

export interface CreateEquipment {
  name?: string;
  thumb?: Thumb
  documentation?: string;
  periodicity?: Periodicity;
  category_id?: number;
  customs: CustomReview[];
}

export interface Thumb {
    file_name: string,
    url: string
}

export interface CustomReview {
  name: string,
  periodicity: Periodicity
}

export interface PeriodicityMap {
  id: string,
  title: string
}

export enum Periodicity {
  EVERY_MONTH="EVERY_MONTH", EVERY_QUARTER="EVERY_QUARTER", EVERY_HALF_YEAR="EVERY_HALF_YEAR", EVERY_YEAR="EVERY_YEAR"
}

export function createEquipment(params: Partial<Equipment>) {
  return {

  } as Equipment;
}

export function createPeriodicityMap(): PeriodicityMap[] {
  return [
    Periodicity.EVERY_MONTH,
    Periodicity.EVERY_QUARTER,
    Periodicity.EVERY_HALF_YEAR,
    Periodicity.EVERY_YEAR
  ].map(i => {
    let title: string
    switch (i) {
      case Periodicity.EVERY_MONTH:
        title = "Раз в месяц"
        break;
      case Periodicity.EVERY_QUARTER:
        title = "Раз в квартал"
        break;
      case Periodicity.EVERY_HALF_YEAR:
        title = "Раз в полгода"
        break;
      case Periodicity.EVERY_YEAR:
        title = "Раз в год"
        break;

    }

    return {
      id: i.valueOf(),
      title: title
    }
  })
}

export function reversePeriodicityMap(title: string): Periodicity {
  let periodicity: Periodicity;
  switch (title) {
    case "Раз в месяц":
      periodicity = Periodicity.EVERY_MONTH
      break;
    case "Раз в квартал":
      periodicity = Periodicity.EVERY_QUARTER
      break;
    case "Раз в полгода":
      periodicity = Periodicity.EVERY_HALF_YEAR
      break;
    case "Раз в год":
      periodicity = Periodicity.EVERY_YEAR
      break;
    default:
      periodicity = Periodicity.EVERY_MONTH
  }

  return periodicity
}